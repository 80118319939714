/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
 */
export interface FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    AppId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    AuthKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    Debug: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    GameSid: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    PlayerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    Guest: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions
     */
    Invite: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AppId" in value;
    isInstance = isInstance && "AuthKey" in value;
    isInstance = isInstance && "Debug" in value;
    isInstance = isInstance && "GameSid" in value;
    isInstance = isInstance && "PlayerId" in value;
    isInstance = isInstance && "Guest" in value;
    isInstance = isInstance && "Invite" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions {
    return FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AppId': json['app_id'],
        'AuthKey': json['auth_key'],
        'Debug': json['debug'],
        'GameSid': json['game_sid'],
        'PlayerId': json['player_id'],
        'Guest': json['guest'],
        'Invite': json['invite'],
    };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'app_id': value.AppId,
        'auth_key': value.AuthKey,
        'debug': value.Debug,
        'game_sid': value.GameSid,
        'player_id': value.PlayerId,
        'guest': value.Guest,
        'invite': value.Invite,
    };
}

