/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsEGameOrientation } from './FxIdDomainSettingsEGameOrientation';
import {
    FxIdDomainSettingsEGameOrientationFromJSON,
    FxIdDomainSettingsEGameOrientationFromJSONTyped,
    FxIdDomainSettingsEGameOrientationToJSON,
} from './FxIdDomainSettingsEGameOrientation';
import type { FxIdDomainSettingsPlayGameType } from './FxIdDomainSettingsPlayGameType';
import {
    FxIdDomainSettingsPlayGameTypeFromJSON,
    FxIdDomainSettingsPlayGameTypeFromJSONTyped,
    FxIdDomainSettingsPlayGameTypeToJSON,
} from './FxIdDomainSettingsPlayGameType';
import type { FxIdDomainSettingsPublicWebClientConfigOptions } from './FxIdDomainSettingsPublicWebClientConfigOptions';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptions';
import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
} from './FxIdDomainSettingsUrlOpenMethod';
import type { FxIdWebFeaturesPlayGameResponseAllOfIframeGame } from './FxIdWebFeaturesPlayGameResponseAllOfIframeGame';
import {
    FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSON,
    FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseAllOfIframeGameToJSON,
} from './FxIdWebFeaturesPlayGameResponseAllOfIframeGame';
import type { FxIdWebFeaturesPlayGameResponseAllOfLaunchData } from './FxIdWebFeaturesPlayGameResponseAllOfLaunchData';
import {
    FxIdWebFeaturesPlayGameResponseAllOfLaunchDataFromJSON,
    FxIdWebFeaturesPlayGameResponseAllOfLaunchDataFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseAllOfLaunchDataToJSON,
} from './FxIdWebFeaturesPlayGameResponseAllOfLaunchData';
import type { FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig } from './FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig';
import {
    FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSON,
    FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigToJSON,
} from './FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig';
import type { FxIdWebFeaturesPlayPlatformDataOptions } from './FxIdWebFeaturesPlayPlatformDataOptions';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptions';
import type { FxIdWebFeaturesPlayPublicDataBaseAdvertisement } from './FxIdWebFeaturesPlayPublicDataBaseAdvertisement';
import {
    FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseAdvertisementToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseAdvertisement';
import type { FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptions } from './FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptions';
import {
    FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptions';
import type { FxIdWebFeaturesPlayPublicDataBasePresentation } from './FxIdWebFeaturesPlayPublicDataBasePresentation';
import {
    FxIdWebFeaturesPlayPublicDataBasePresentationFromJSON,
    FxIdWebFeaturesPlayPublicDataBasePresentationFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBasePresentationToJSON,
} from './FxIdWebFeaturesPlayPublicDataBasePresentation';
import type { FxIdWebFeaturesPlayPublicDataBaseStatistics } from './FxIdWebFeaturesPlayPublicDataBaseStatistics';
import {
    FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseStatistics';
import type { FxIdWebFeaturesPlayPublicDataBaseStoreOptions } from './FxIdWebFeaturesPlayPublicDataBaseStoreOptions';
import {
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseStoreOptions';
import type { FxIdWebFeaturesPlayPublicDataBaseSupport } from './FxIdWebFeaturesPlayPublicDataBaseSupport';
import {
    FxIdWebFeaturesPlayPublicDataBaseSupportFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseSupportFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseSupportToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseSupport';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayGameResponse
 */
export interface FxIdWebFeaturesPlayGameResponse {
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PlatformData: FxIdWebFeaturesPlayPlatformDataOptions;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseStatistics}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Statistics?: FxIdWebFeaturesPlayPublicDataBaseStatistics | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseAdvertisement}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Advertisement?: FxIdWebFeaturesPlayPublicDataBaseAdvertisement | null;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    MobileGameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Presentation?: FxIdWebFeaturesPlayPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    MobilePresentation?: FxIdWebFeaturesPlayPublicDataBasePresentation | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBasePresentation}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    ViewportPresentation?: FxIdWebFeaturesPlayPublicDataBasePresentation | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    SupportedLanguages: Array<string>;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseSupport}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Support?: FxIdWebFeaturesPlayPublicDataBaseSupport | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseStoreOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    Store: FxIdWebFeaturesPlayPublicDataBaseStoreOptions;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    FxLauncherOptions?: FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptions | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    CompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    TosUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PpUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptions}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PublicWebClientConfig: FxIdDomainSettingsPublicWebClientConfigOptions;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameSystemName: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    NamespacedProductsL10N: boolean;
    /**
     * 
     * @type {FxIdDomainSettingsPlayGameType}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    PlayGameType: FxIdDomainSettingsPlayGameType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    GameJwt: string;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    UnityGameConfig?: FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseAllOfIframeGame}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    IframeGame?: FxIdWebFeaturesPlayGameResponseAllOfIframeGame | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseAllOfLaunchData}
     * @memberof FxIdWebFeaturesPlayGameResponse
     */
    LaunchData?: FxIdWebFeaturesPlayGameResponseAllOfLaunchData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesPlayGameResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PlatformData" in value;
    isInstance = isInstance && "GameOrientation" in value;
    isInstance = isInstance && "MobileGameOrientation" in value;
    isInstance = isInstance && "SupportedLanguages" in value;
    isInstance = isInstance && "Store" in value;
    isInstance = isInstance && "CompanyName" in value;
    isInstance = isInstance && "TosUrl" in value;
    isInstance = isInstance && "TosUrlOpenMethod" in value;
    isInstance = isInstance && "PpUrl" in value;
    isInstance = isInstance && "PpUrlOpenMethod" in value;
    isInstance = isInstance && "PublicWebClientConfig" in value;
    isInstance = isInstance && "GameSystemName" in value;
    isInstance = isInstance && "NamespacedProductsL10N" in value;
    isInstance = isInstance && "PlayGameType" in value;
    isInstance = isInstance && "GameJwt" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayGameResponseFromJSON(json: any): FxIdWebFeaturesPlayGameResponse {
    return FxIdWebFeaturesPlayGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PlatformData': FxIdWebFeaturesPlayPlatformDataOptionsFromJSON(json['PlatformData']),
        'Statistics': !exists(json, 'Statistics') ? undefined : FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSON(json['Statistics']),
        'Advertisement': !exists(json, 'Advertisement') ? undefined : FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSON(json['Advertisement']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['MobileGameOrientation']),
        'Presentation': !exists(json, 'Presentation') ? undefined : FxIdWebFeaturesPlayPublicDataBasePresentationFromJSON(json['Presentation']),
        'MobilePresentation': !exists(json, 'MobilePresentation') ? undefined : FxIdWebFeaturesPlayPublicDataBasePresentationFromJSON(json['MobilePresentation']),
        'ViewportPresentation': !exists(json, 'ViewportPresentation') ? undefined : FxIdWebFeaturesPlayPublicDataBasePresentationFromJSON(json['ViewportPresentation']),
        'SupportedLanguages': json['SupportedLanguages'],
        'Support': !exists(json, 'Support') ? undefined : FxIdWebFeaturesPlayPublicDataBaseSupportFromJSON(json['Support']),
        'Store': FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON(json['Store']),
        'FxLauncherOptions': !exists(json, 'FxLauncherOptions') ? undefined : FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptionsFromJSON(json['FxLauncherOptions']),
        'CompanyName': json['CompanyName'],
        'TosUrl': json['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'PpUrl': json['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json['PublicWebClientConfig']),
        'GameSystemName': json['GameSystemName'],
        'NamespacedProductsL10N': json['NamespacedProductsL10N'],
        'PlayGameType': FxIdDomainSettingsPlayGameTypeFromJSON(json['PlayGameType']),
        'GameJwt': json['GameJwt'],
        'UnityGameConfig': !exists(json, 'UnityGameConfig') ? undefined : FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSON(json['UnityGameConfig']),
        'IframeGame': !exists(json, 'IframeGame') ? undefined : FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSON(json['IframeGame']),
        'LaunchData': !exists(json, 'LaunchData') ? undefined : FxIdWebFeaturesPlayGameResponseAllOfLaunchDataFromJSON(json['LaunchData']),
    };
}

export function FxIdWebFeaturesPlayGameResponseToJSON(value?: FxIdWebFeaturesPlayGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PlatformData': FxIdWebFeaturesPlayPlatformDataOptionsToJSON(value.PlatformData),
        'Statistics': FxIdWebFeaturesPlayPublicDataBaseStatisticsToJSON(value.Statistics),
        'Advertisement': FxIdWebFeaturesPlayPublicDataBaseAdvertisementToJSON(value.Advertisement),
        'GameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value.GameOrientation),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value.MobileGameOrientation),
        'Presentation': FxIdWebFeaturesPlayPublicDataBasePresentationToJSON(value.Presentation),
        'MobilePresentation': FxIdWebFeaturesPlayPublicDataBasePresentationToJSON(value.MobilePresentation),
        'ViewportPresentation': FxIdWebFeaturesPlayPublicDataBasePresentationToJSON(value.ViewportPresentation),
        'SupportedLanguages': value.SupportedLanguages,
        'Support': FxIdWebFeaturesPlayPublicDataBaseSupportToJSON(value.Support),
        'Store': FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON(value.Store),
        'FxLauncherOptions': FxIdWebFeaturesPlayPublicDataBaseFxLauncherOptionsToJSON(value.FxLauncherOptions),
        'CompanyName': value.CompanyName,
        'TosUrl': value.TosUrl,
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value.TosUrlOpenMethod),
        'PpUrl': value.PpUrl,
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value.PpUrlOpenMethod),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(value.PublicWebClientConfig),
        'GameSystemName': value.GameSystemName,
        'NamespacedProductsL10N': value.NamespacedProductsL10N,
        'PlayGameType': FxIdDomainSettingsPlayGameTypeToJSON(value.PlayGameType),
        'GameJwt': value.GameJwt,
        'UnityGameConfig': FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigToJSON(value.UnityGameConfig),
        'IframeGame': FxIdWebFeaturesPlayGameResponseAllOfIframeGameToJSON(value.IframeGame),
        'LaunchData': FxIdWebFeaturesPlayGameResponseAllOfLaunchDataToJSON(value.LaunchData),
    };
}

