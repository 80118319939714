import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesAuthAuthenticateFromSocialRequest,
	FxIdWebFeaturesAuthAuthenticateFromSocialResponse,
	FxIdWebFeaturesFriendsGetFriendsResponse
} from "../Api/gen";
import { OS } from "@mantine/hooks/lib/use-os/use-os";
import { IVkAdsConfig } from "../vkplay";

export interface IFxIdSdkAdapterOpenModalRequestDto {
	Url: string;
}

export interface IFxIdSdkAdapterOpenModalResultDto {
	Success: boolean;
}

export interface IFxIdSdkAdapterPushTokenResponseDto {
	Token?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkAdapterEmptyDto {
	// ignore
}

export enum FxIdSdkAdapterStickyBannerShowErrorReason {
	Unknown = 0,
	AdsNotConnected = 1,
	NotImplemented = 999
}

export interface IFxIdSdkAdapterStickyBannerShowResultDto {
	success: boolean;
	errorReason?: FxIdSdkAdapterStickyBannerShowErrorReason;
}

export interface IFxIdSdkGetUrlHashDto {
	hash: string;
}

export interface IFxIdSdkSetUrlHashDto {
	hash: string;
}

export interface IFxIdSdkShowLinkShareModalDto {
	Link: string;
}

export interface IFxIdSdkSetStatusDto {
	name: string;
	status: string;
	priority: number;
}

export interface ISafeArea {
	left: number;
	right: number;
	top: number;
	bottom: number;
}

export interface IFxIdSdkAdapterInfo {
	game: string;
	environment: {
		os: OS;
		isMobile: boolean;
		safeArea: ISafeArea;
	};
	fxId: {
		userId: string;
		marketingEnabled: boolean;
		locale: string;
		geoCountry: string;
		token: string;
		gameToken: string;
		gameUid: string;
		guest: boolean;
		hasEmail: boolean;
		emailValidated: boolean;
		hasCompletedOrder: boolean;
	};
	social: IFxIdSdkAdapterInfoSocial;
}

export enum EFxIdSdkAdapterInfoSocialCapability {
	/**
	 * Поддерживается список друзей
	 */
	FriendsList = 0,
	/**
	 * Поддерживается приглашение друзей
	 */
	FriendsInvite = 1,
	/**
	 * Необходимо отключать игры с случайным исходом (рулетка итд)
	 */
	GamesOfChanceDisabled = 2,
	/**
	 * Можно ли показывать дилог с предложением поставить оценку
	 */
	RateApplication = 3
}

export type TSocialFlavor = "vkcom-direct";

export interface IFxIdSdkAdapterInfoSocial {
	social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
	socialFlavor?: TSocialFlavor;
	userId: string;
	capabilities: EFxIdSdkAdapterInfoSocialCapability[];

	// Опциональные данные - доступны не везде и не на всех платформах
	displayName?: string;
	firstName?: string;
	lastName?: string;
	gender?: EFxIdSdkGender;
	// Некоторые платформы предоставляют данные о размере аватар, некоторые нет
	photo?: {
		width?: number;
		height?: number;
		url: string;
	};
	// Некоторые платформы позволяют установить свой язык пользователя
	socialLocale?: string;
	vkcom?: IFxIdSdkAdapterInfoSocialVkCom;
}

export interface IFxIdSdkAdapterInfoSocialVkCom {
	appId: number;
	defaultApiVersion: string;
	defaultAccessToken: string;
}

export interface IFxIdSdkAdapterBuyProductRequest {
	sku: string;
}

export interface IFxIdSdkAdapterStatInitializeRequest {
	userId: string;
	userLevel: number;
	buildVersion: string;
	trackingEnabled?: boolean;
}

export interface IFxIdSdkAdapterStatEventRequest {
	name: string;
	type: string;
	subtype: string;
	section: string;
	value: number;
	data?: string;
}

export interface IFxIdSdkAdapterChangeLanguageRequest {
	lang: string;
}

export interface IFxIdSendVkBridgeRequest {
	method: string;
	props: any;
}

export interface IFxIdSendCrazyGamesSdkRequest {
	method: string;
	props?: any;
}

export interface IFxIdSdkAdapterBuyProductResponse {
	error?: string;
	transactionId?: string;
	errorCode?: EFxIdSdkAdapterBuyProductResponseErrorCode;
}

export enum EFxIdSdkAdapterBuyProductResponseErrorCode {
	Unknown = 0,
	UserCancelled = 1
}

export const FxIdSdkAdapterSocialSettingDefault: IFxIdSdkAdapterSocialSettings = {
	ui: {
		disabled: false
	}
};

export interface IFxIdSdkAdapterSocialSettings {
	ui: {
		// Если тру, то не показываем оформление совсем
		disabled: boolean;
	};
}

export interface IFxIdSdkAuthenticateApiResult {
	authResponse: FxIdWebFeaturesAuthAuthenticateFromSocialResponse;
	changeEvent?: IFxIdSdkOnAccountChangedEvent;
	changeInfoEvent?: IFxIdSdkOnChangeUserInfoEvent;
}

export interface IFxIdSdkOnAccountChangedEvent {
	oldAccountId: number;
	newAccountId: number;
}

export enum EFxIdSdkFocusChangedReason {
	Hint = "hint",
	Pip = "pip",
	Other = "other"
}
export interface IFxIdSdkOnFocusChangedEvent {
	focus: boolean;
	reason?: EFxIdSdkFocusChangedReason;
	hint?: string;
}

export interface IFxIdSdkOnChangeUserInfoEvent {
	newInfo: IFxIdSdkAdapterInfo;
}

export interface IFxIdSdkOnChangeLanguageEvent {
	language: string;
}

export interface IFxIdSdkAuthenticateUser {
	preferredSocials?: string[];
	force?: {
		providerId: string;
	};
	import?: {
		socialIdentifier: string;
		socialIdentifierType: string;
	};
}

export enum EFxIdSdkGender {
	Unspecified = 0,
	Female,
	Male = 2
}

export interface IFxIdSdkGetFriendsResultFriend {
	firstName?: string;
	lastName?: string;
	displayName?: string;
	uid: string;
	gender?: EFxIdSdkGender;
	photo?: {
		width?: number;
		height?: number;
		url: string;
	};
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkAdapterGetFriendsRequest {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkAdapterInviteFriendsRequest {
	inviteText: string;
	trackString?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkAdapterAuthenticateUserRequest {
	// ignore
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkAdapterAuthenticateUserResponse {
	// ignore
}

export interface IFxIdSdkGetFriendsResult {
	friends: IFxIdSdkGetFriendsResultFriend[];
}

export interface IFxIdSdkInviteFriendsResult {
	friends: IFxIdSdkGetFriendsResultFriend[];
}

export interface IFxIdSdkGetFriendsResultForSdk extends FxIdWebFeaturesFriendsGetFriendsResponse {
	socialFriends: IFxIdSdkGetFriendsResultFriend[];
}

export interface IExportedFxIdSdkMethod {
	AuthenticateUser(requestJson: string): Promise<void>;
	AuthenticateApi(request: FxIdWebFeaturesAuthAuthenticateFromSocialRequest): Promise<IFxIdSdkAuthenticateApiResult>;
	IsAuthenticationApiInProgress(): boolean;
	DispatchAdsFinished(): void;
	DispatchAdsSkipped(): void;
	DispatchAdsFailed(): void;
	DispatchFocusChanged(data: IFxIdSdkOnFocusChangedEvent): void;
}

export interface IFxIdJoinCommunityRequest {
	community_id: string;
}

export interface IFxIdJoinCommunityResult {
	success: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdAddToFavoritesRequest {
	// ignore
}

export interface IFxIdAddToFavoritesResult {
	success: boolean;
}

export interface IFxIdSdkWordsFilterRequest {
	text: string;
}

export interface IFxIdSdkWordsFilterResult {
	success: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFxIdSdkRateApplicationRequest {
	// ignore
}

export interface IFxIdSdkRateApplicationResult {
	success: boolean;
}

export interface IFxIdSdkAdapterConsentManagerStatus {
	consentExists: boolean;
	consentData: any;
	cmpData: any;
}

export abstract class FxIdSdkBaseAdapter {
	protected constructor(protected exportedSdk: IExportedFxIdSdkMethod) {}

	abstract Initialize(): Promise<void>;

	abstract GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial>;

	abstract BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse>;

	abstract SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings>;

	abstract RegisterShareHandlers(): Promise<void>;

	abstract StoreCurrency(): Promise<string | undefined>;
	abstract StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void>;

	abstract StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void>;

	StoreAdditionalData(): Promise<unknown | undefined> {
		return Promise.resolve(undefined);
	}

	AdsIsVideoReady(): Promise<boolean> {
		// ignore
		return Promise.resolve(false);
	}

	AdsShowVideo(): Promise<void> {
		// ignore
		return Promise.resolve();
	}

	AdsStickyBannerShow(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		return Promise.resolve({
			success: false,
			errorReason: FxIdSdkAdapterStickyBannerShowErrorReason.NotImplemented
		});
	}

	AdsStickyBannerHide(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		return Promise.resolve({
			success: false,
			errorReason: FxIdSdkAdapterStickyBannerShowErrorReason.NotImplemented
		});
	}

	GetFriends(_request: IFxIdSdkAdapterGetFriendsRequest): Promise<IFxIdSdkGetFriendsResult> {
		return Promise.resolve({ friends: [] });
	}

	InviteFriends(_request: IFxIdSdkAdapterInviteFriendsRequest): Promise<IFxIdSdkInviteFriendsResult> {
		return Promise.resolve({ friends: [] });
	}

	AuthenticateUser(
		_request: IFxIdSdkAdapterAuthenticateUserRequest
	): Promise<IFxIdSdkAdapterAuthenticateUserResponse> {
		return Promise.resolve({});
	}

	JoinCommunity(_request: IFxIdJoinCommunityRequest): Promise<IFxIdJoinCommunityResult> {
		return Promise.resolve({ success: false });
	}

	AddToFavorites(_request: IFxIdAddToFavoritesRequest): Promise<IFxIdAddToFavoritesResult> {
		return Promise.resolve({ success: false });
	}

	WordsFilter(request: IFxIdSdkWordsFilterRequest): Promise<IFxIdSdkWordsFilterResult> {
		return Promise.resolve({ success: true });
	}

	RateApplication(request: IFxIdSdkRateApplicationRequest): Promise<IFxIdSdkRateApplicationResult> {
		return Promise.resolve({ success: true });
	}

	IsFocusChanged() {
		const eventHandler = () => {
			// Проверяем, скрыта ли страница
			if (document.hidden) {
				this.exportedSdk.DispatchFocusChanged({ focus: false });
			} else {
				this.exportedSdk.DispatchFocusChanged({ focus: true });
			}
		};
		document.addEventListener("visibilitychange", eventHandler);
	}
}
