import {
	FxIdDomainStoreEnumsOrderState,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesStoreCreatePaymentEmbeddingType,
	FxIdWebFeaturesStoreCreatePaymentResponse,
	FxIdWebFeaturesStoreGetPaymentStatusResponse,
	FxIdWebFeaturesStoreStoreAvailabilityResponse,
	FxIdWebFeaturesStoreStoreDataResponse
} from "./gen";
import OpenApiClient from "./OpenApiClient";
import * as Analytics from "../Utils/Analytics";
import i18next from "i18next";

export const getStoreData = async (
	game: string,
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform
): Promise<FxIdWebFeaturesStoreStoreDataResponse> => {
	try {
		return await OpenApiClient.Store.fxIdWebFeaturesStoreStoreDataEndpoint({
			Game: game,
			WebPublishingPlatform: platform,
			Locale: i18next.language
		});
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const createPayment = async (
	game: string,
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	sku: string,
	currency: string,
	embedType: FxIdWebFeaturesStoreCreatePaymentEmbeddingType
): Promise<FxIdWebFeaturesStoreCreatePaymentResponse> => {
	try {
		const result = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: game,
			Sku: sku,
			Currency: currency,
			WebPublishingPlatform: platform,
			EmbeddingType: embedType
		});

		void Analytics.site.requestPurchase({ game, product_sku: sku });

		return result;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const getStoreAvailability = async (): Promise<FxIdWebFeaturesStoreStoreAvailabilityResponse> => {
	try {
		return await OpenApiClient.Store.fxIdWebFeaturesStoreStoreAvailabilityEndpoint();
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const getPaymentStatus = async (id: string): Promise<FxIdWebFeaturesStoreGetPaymentStatusResponse> => {
	try {
		const response: FxIdWebFeaturesStoreGetPaymentStatusResponse = await (
			await OpenApiClient.Store.fxIdWebFeaturesStoreGetPaymentStatusEndpointRaw({
				fxIdWebFeaturesStoreGetPaymentStatusRequest: { PaymentId: id }
			})
		).raw.json();
		if (response.Status === FxIdDomainStoreEnumsOrderState.Completed) {
			void Analytics.site.confirmPurchase({
				game: response.Game,
				product_sku: response.OrderProducts.MainProduct.Sku,
				order_id: id
			});
		}
		return response;
	} catch (err) {
		console.error(err);
		throw err;
	}
};
