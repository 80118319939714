import { FxIdWebFeaturesPlayGamePublicConfigResponse } from "../../Api/gen";
import { authStore } from "../../Stores";

/**
 * Авторизует пользоватея с помощью выбранного провайдера
 *
 * ! В случае, если провайдер не найден или процесс авторизации не прошел успешно, выбрасывает ошибку.
 *
 * @param providerId ID провайдера авторизации (из authStore)
 * @param init Настройки для правильной инициализации авторизации. Зависят от выбранного провайдера
 */
export async function signInWithProvider(
	providerId: string,
	publicConfig: FxIdWebFeaturesPlayGamePublicConfigResponse
) {
	const handleAuth = authStore.getState().authOptions.get(providerId)?.handleAuth;
	const createInitOptions = authStore.getState().authOptions.get(providerId)?.createInitOptions;
	if (!handleAuth || !createInitOptions)
		throw new Error("Sign in method for given provider not found. Aborting sign in");
	try {
		await handleAuth(createInitOptions(publicConfig));
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error("Error while signing in with provider:", providerId);
		throw error;
	}
}
