/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPublicDataBaseAdvOptions,
    instanceOfFxIdWebFeaturesPlayPublicDataBaseAdvOptions,
    FxIdWebFeaturesPlayPublicDataBaseAdvOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseAdvOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseAdvOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseAdvOptions';

/**
 * @type FxIdWebFeaturesPlayPublicDataBaseAdvertisement
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPublicDataBaseAdvertisement = FxIdWebFeaturesPlayPublicDataBaseAdvOptions;

export function FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseAdvertisement {
    return FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseAdvertisementFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseAdvertisement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPublicDataBaseAdvOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPublicDataBaseAdvertisementToJSON(value?: FxIdWebFeaturesPlayPublicDataBaseAdvertisement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseAdvOptions(value)) {
        return FxIdWebFeaturesPlayPublicDataBaseAdvOptionsToJSON(value as FxIdWebFeaturesPlayPublicDataBaseAdvOptions);
    }

    return {};
}

