/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType,
  FxIdWebFeaturesInfoNewsRequest,
  FxIdWebFeaturesInfoNewsResponse,
} from '../models';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
    FxIdWebFeaturesInfoNewsRequestFromJSON,
    FxIdWebFeaturesInfoNewsRequestToJSON,
    FxIdWebFeaturesInfoNewsResponseFromJSON,
    FxIdWebFeaturesInfoNewsResponseToJSON,
} from '../models';

export interface FxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpointRequest {
    game: string | null;
    socialIdentifierType: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    socialIdentifier: string | null;
}

export interface FxIdWebFeaturesInfoNewsEndpointRequest {
    fxIdWebFeaturesInfoNewsRequest: FxIdWebFeaturesInfoNewsRequest;
}

/**
 * 
 */
export class InfoApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpointRaw(requestParameters: FxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpoint.');
        }

        if (requestParameters.socialIdentifierType === null || requestParameters.socialIdentifierType === undefined) {
            throw new runtime.RequiredError('socialIdentifierType','Required parameter requestParameters.socialIdentifierType was null or undefined when calling fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpoint.');
        }

        if (requestParameters.socialIdentifier === null || requestParameters.socialIdentifier === undefined) {
            throw new runtime.RequiredError('socialIdentifier','Required parameter requestParameters.socialIdentifier was null or undefined when calling fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/info/check_account_exists_by_social_identifier/{game}/{socialIdentifierType}/{socialIdentifier}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))).replace(`{${"socialIdentifierType"}}`, encodeURIComponent(String(requestParameters.socialIdentifierType))).replace(`{${"socialIdentifier"}}`, encodeURIComponent(String(requestParameters.socialIdentifier))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpoint(game: string | null, socialIdentifierType: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType, socialIdentifier: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesInfoCheckAccountExistsBySocialIdentifierEndpointRaw({ game: game, socialIdentifierType: socialIdentifierType, socialIdentifier: socialIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesInfoNewsEndpointRaw(requestParameters: FxIdWebFeaturesInfoNewsEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesInfoNewsResponse>> {
        if (requestParameters.fxIdWebFeaturesInfoNewsRequest === null || requestParameters.fxIdWebFeaturesInfoNewsRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesInfoNewsRequest','Required parameter requestParameters.fxIdWebFeaturesInfoNewsRequest was null or undefined when calling fxIdWebFeaturesInfoNewsEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/info/news`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesInfoNewsRequestToJSON(requestParameters.fxIdWebFeaturesInfoNewsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesInfoNewsResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesInfoNewsEndpoint(fxIdWebFeaturesInfoNewsRequest: FxIdWebFeaturesInfoNewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesInfoNewsResponse> {
        const response = await this.fxIdWebFeaturesInfoNewsEndpointRaw({ fxIdWebFeaturesInfoNewsRequest: fxIdWebFeaturesInfoNewsRequest }, initOverrides);
        return await response.value();
    }

}
