import {
	FxIdWebFeaturesPlayOtaGameVersion,
	FxIdWebFeaturesPlayListResponse,
	FxIdWebFeaturesProfileGetResponseFeature
} from "../gen";
import OpenApiClient from "../OpenApiClient";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { QueryFunction } from "@tanstack/query-core/src/types";
import { useProfileDataQuery } from "./profileDataQuery";
import { getStringEnumValue } from "../../Utils/Functions";

interface IUseGamePlayListQuery {
	game: string | null | undefined;
	otaVersion: string | null | undefined;
}

type TQueryKeyPlayList = [string, IUseGamePlayListQuery];

const getPlayListData: QueryFunction<FxIdWebFeaturesPlayListResponse, TQueryKeyPlayList> = async ({
	queryKey
}): Promise<FxIdWebFeaturesPlayListResponse> => {
	const [_key, { game, otaVersion }] = queryKey;
	if (!game) throw new Error("No game name provided!");
	const version = getStringEnumValue(FxIdWebFeaturesPlayOtaGameVersion, otaVersion);
	if (!version) throw new Error("No game version or game version incorrect!", version);
	return await OpenApiClient.Play.fxIdWebFeaturesPlayListEndpoint(game, version);
};

export const useGamePlayListQuery = (
	params: IUseGamePlayListQuery,
	options?: Omit<
		UseQueryOptions<FxIdWebFeaturesPlayListResponse, unknown, FxIdWebFeaturesPlayListResponse, TQueryKeyPlayList>,
		"queryFn"
	>
) => {
	const { game, otaVersion } = params;
	const { data } = useProfileDataQuery({ staleTime: Infinity });

	const enabled =
		Boolean(data?.Features.includes(FxIdWebFeaturesProfileGetResponseFeature.ListGames)) &&
		otaVersion !== null &&
		otaVersion !== undefined &&
		(options?.enabled ?? true);

	return useQuery(["PlayList", { game, otaVersion }], getPlayListData, {
		...options,
		enabled
	});
};
