import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FxIdDomainStoreEnumsSupportedWebPublishingPlatform, FxIdWebFeaturesStoreStoreDataResponse } from "../gen";
import { getStoreData } from "../Store";

export const useStoreDataQuery = (
	game: string,
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	options?: Omit<UseQueryOptions<FxIdWebFeaturesStoreStoreDataResponse>, "queryKey">
) => {
	return useQuery(
		["storeData", game],
		() => {
			if (!game) throw new Error();
			return getStoreData(game, platform);
		},
		options
	);
};
