/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainStoreStoreDiscountData } from './FxIdDomainStoreStoreDiscountData';
import {
    FxIdDomainStoreStoreDiscountDataFromJSON,
    FxIdDomainStoreStoreDiscountDataFromJSONTyped,
    FxIdDomainStoreStoreDiscountDataToJSON,
} from './FxIdDomainStoreStoreDiscountData';

/**
 * 
 * @export
 * @interface FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames
 */
export interface FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames extends FxIdDomainStoreStoreDiscountData {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames
     */
    PercentToAddToOtherGames: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames
     */
    UseProductWithLabels: Array<string>;
}

/**
 * Check if a given object implements the FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames interface.
 */
export function instanceOfFxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PercentToAddToOtherGames" in value;
    isInstance = isInstance && "UseProductWithLabels" in value;

    return isInstance;
}

export function FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGamesFromJSON(json: any): FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames {
    return FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGamesFromJSONTyped(json, false);
}

export function FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...FxIdDomainStoreStoreDiscountDataFromJSONTyped(json, ignoreDiscriminator),
        'PercentToAddToOtherGames': json['PercentToAddToOtherGames'],
        'UseProductWithLabels': json['UseProductWithLabels'],
    };
}

export function FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGamesToJSON(value?: FxIdDomainStoreStoreDiscountDataBOGOPercentToOtherGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...FxIdDomainStoreStoreDiscountDataToJSON(value),
        'PercentToAddToOtherGames': value.PercentToAddToOtherGames,
        'UseProductWithLabels': value.UseProductWithLabels,
    };
}

