import { TStandardSdkEventParams } from "./types";
import { getQueriedProfileData } from "../../Api/Queries";

export async function forceUserAuthentication(params: TStandardSdkEventParams & { provider_id: string }) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_user_authentication_forced",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function logUserInFxId(params: TStandardSdkEventParams) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_user_logged_in_fxid",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function openIframeModal(params: TStandardSdkEventParams & { url: string }) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_iframe_modal_opened",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function changeLanguage(params: TStandardSdkEventParams & { old_lang: string; new_lang: string }) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_language_changed",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function reportIssue(params: TStandardSdkEventParams) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_report_modal_opened",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function buyProduct(params: TStandardSdkEventParams & { product_sku: string }) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_product_purchase_requested",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function gameStart(params: TStandardSdkEventParams) {
	console.log("Event gameStart...");

	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "sdk_game_started",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}
