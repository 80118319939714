/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseYandexGames
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseYandexGames = FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseYandexGames {
    return FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseYandexGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseYandexGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesMerchantData);
    }

    return {};
}

