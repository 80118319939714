/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions,
    instanceOfFxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames = FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions;

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames {
    return FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions(value)) {
        return FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptionsToJSON(value as FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesOptions);
    }

    return {};
}

