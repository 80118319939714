import { TStandardSdkEventParams } from "./types";
import { getQueriedProfileData } from "../../Api/Queries";

export async function openFxIdPortal() {
	const profile = await getQueriedProfileData();

	const sendedObject = {
		event: "portal_opened",
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function openLoginModal(params: TStandardSdkEventParams) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "site_login_modal_opened",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function login(params: { method: string }) {
	const profile = await getQueriedProfileData();
	window.dataLayer?.push({ event: "login", ...params, account_id: profile.AccountId });

	console.log("Профиль: ", profile);

	for (const gameData of profile.ConnectedGames) {
		const sendedObject = {
			event: "login",
			...params,
			account_id: profile.AccountId,
			game: gameData.Game,
			user_id: gameData.GameUid,
			email: profile.Email
		};
		console.log("Пушим объект: ", sendedObject);

		window.dataLayer?.push(sendedObject);
	}
}

export async function requestPurchase(params: { game: string; product_sku: string }) {
	const profile = await getQueriedProfileData();

	const gameData = profile.ConnectedGames.find((el) => el.Game.toLowerCase() === params.game.toLowerCase());
	const sendedObject = {
		event: "site_purchase_requested",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function confirmPurchase(params: { game: string; product_sku: string; order_id: string }) {
	const profile = await getQueriedProfileData();

	const gameData = profile.ConnectedGames.find((el) => el.Game.toLowerCase() === params.game.toLowerCase());

	const sendedObject = {
		event: "site_purchase_confirmed",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function install(params: { game: string }) {
	const profile = await getQueriedProfileData();

	const gameData = profile.ConnectedGames.find((el) => el.Game.toLowerCase() === params.game.toLowerCase());

	const sendedObject = {
		event: "site_install",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}

export async function share(params: TStandardSdkEventParams & { method: string }) {
	const profile = await getQueriedProfileData();

	const game = params.game;
	const gameData =
		game == null ? undefined : profile.ConnectedGames.find((el) => el.Game.toLowerCase() === game.toLowerCase());

	const sendedObject = {
		event: "site_share",
		...params,
		account_id: profile.AccountId,
		user_id: gameData?.GameUid,
		email: profile.Email
	};

	console.log("Профиль: ", profile);
	console.log("Пушим объект: ", sendedObject);

	window.dataLayer?.push(sendedObject);
}
