/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData
 */
export interface FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData
     */
    Redirect: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData
     */
    ConfirmationUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData
     */
    ConfirmationToken?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Redirect" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData {
    return FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Redirect': json['Redirect'],
        'ConfirmationUrl': !exists(json, 'ConfirmationUrl') ? undefined : json['ConfirmationUrl'],
        'ConfirmationToken': !exists(json, 'ConfirmationToken') ? undefined : json['ConfirmationToken'],
    };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Redirect': value.Redirect,
        'ConfirmationUrl': value.ConfirmationUrl,
        'ConfirmationToken': value.ConfirmationToken,
    };
}

