/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions,
    instanceOfFxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay = FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions;

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay {
    return FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions(value)) {
        return FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsToJSON(value as FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions);
    }

    return {};
}

