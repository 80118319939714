/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainEntitiesNativePlatform {
    Unspecified = 0,
    Editor = 1,
    AppStore = 2,
    AndroidGooglePlay = 3,
    AndroidDigicaviar = 4,
    AndroidAmazon = 5,
    StandaloneSteamWin = 6,
    StandaloneSteamMacos = 7,
    StandaloneWindowsStore = 8,
    StandaloneMailRu = 9,
    AndroidRuStore = 10,
    AndroidAppBazar = 11,
    WebGlUnspecified = 12,
    WebGlFxId = 13,
    WebGlVkontaktePlay = 14,
    WebGlOdnoklassniki = 15,
    WebGlVkontakteCom = 16,
    WebGlYandexGames = 17,
    WebGlAbsoluteGames = 18,
    WebGlKongregate = 19,
    StandaloneReplayChecker = 20,
    WebGlFacebookGames = 21,
    AndroidSamsung = 22,
    StandaloneWindowsDesktop = 23,
    Browser = 24,
    BrowserIos = 25,
    BrowserAndroid = 26,
    BrowserWindows = 27,
    BrowserLinux = 28,
    BrowserMacOs = 29,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainEntitiesNativePlatformFromJSON(json: any): FxIdDomainEntitiesNativePlatform {
    return FxIdDomainEntitiesNativePlatformFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesNativePlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesNativePlatform {
    return json as FxIdDomainEntitiesNativePlatform;
}

export function FxIdDomainEntitiesNativePlatformToJSON(value?: FxIdDomainEntitiesNativePlatform | null): any {
    return value as any;
}

