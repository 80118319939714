import { authTokenExists } from "../../Api/Auth";
import { FxIdWebFeaturesProfileGetResponse } from "../../Api/gen";

type UserStatus = ReturnType<typeof getUserStatus>;

export const getUserStatus = (
	profileData: FxIdWebFeaturesProfileGetResponse | undefined,
	isProfileLoading?: boolean,
	authTokenPresent?: boolean
) => {
	const isAuthTokenPresent = authTokenPresent ?? authTokenExists();

	if (isProfileLoading) return "unknown";
	if (!isAuthTokenPresent || profileData === undefined) return "unauthorized";
	if (profileData.HasEmail) return "registered";
	if (profileData.Guest) return "guest";
	return "authorized";
};

export const USER_STATUSES_ANY: readonly UserStatus[] = ["unauthorized", "guest", "authorized", "registered"] as const;
export const USER_STATUSES_WITH_PROFILE: readonly UserStatus[] = ["guest", "authorized", "registered"] as const;
export const USER_STATUSES_WITH_EMAIL: readonly UserStatus[] = ["registered"] as const;
export const USER_STATUSES_WITH_LOGIN_ENABLED: readonly UserStatus[] = ["unauthorized", "guest"] as const;
export const USER_STATUSES_WITH_LOGOUT_ENABLED: readonly UserStatus[] = ["authorized", "registered"] as const;
