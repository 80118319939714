import { getAuthToken } from "../Auth";
import { FxIdWebFeaturesProfileGetResponse } from "../gen";
import OpenApiClient from "../OpenApiClient";

export const getProfileData = async (): Promise<FxIdWebFeaturesProfileGetResponse> => {
	try {
		return await OpenApiClient.Profile.fxIdWebFeaturesProfileGetEndpoint();
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const updateMarketingEnabled = async (marketingEnabled: boolean): Promise<boolean> => {
	try {
		await OpenApiClient.Profile.fxIdWebFeaturesProfileUpdateMarketingEnabledEndpoint({
			MarketingEnabled: marketingEnabled
		});

		return true;
	} catch (err) {
		console.error(err);
		throw err;
	}
};
