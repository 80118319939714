/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseXsolla
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseXsolla = FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseXsolla {
    return FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseXsolla {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseXsollaToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseXsolla | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseXsollaMerchantData);
    }

    return {};
}

