import { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesGamesChooseGamePortalConfig
} from "../../../Api/gen";
import { FxIdSdk } from "../../../SDK/FxIdSdk";
import { IFxIdSdkAdapterSocialSettings, IFxIdSdkOnAccountChangedEvent } from "../../../SDK/FxIdSdkBaseAdapter";
import { useQueryClient } from "@tanstack/react-query";

export type IframeType =
	| "ok"
	| "vkplay"
	| "vkcom"
	| "fxid"
	| "yandex"
	| "ag"
	| "kg"
	| "fbgames"
	| "crazygames"
	| "ymobage"
	| "telegram"
	| "fxlauncher";

export const IframeTypeToSupportedPlatformMap: {
	[index in IframeType]: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
} = {
	ok: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki,
	vkplay: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay,
	vkcom: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom,
	fxid: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId,
	yandex: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames,
	ag: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames,
	kg: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate,
	fbgames: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames,
	crazygames: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames,
	ymobage: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ,
	telegram: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram,
	fxlauncher: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxLauncher
};

export const SupportedPlatformToIframeTypeMap: {
	[index in FxIdDomainStoreEnumsSupportedWebPublishingPlatform]: IframeType;
} = {
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames]: "ag",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId]: "fxid",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate]: "kg",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.NUMBER_unknown_default_open_api]: "fxid",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki]: "ok",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Unspecified]: "fxid",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom]: "vkcom",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay]: "vkplay",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames]: "yandex",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames]: "fbgames",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames]: "crazygames",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ]: "ymobage",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram]: "telegram",
	[FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxLauncher]: "fxlauncher"
};
export const useFxIdSdk = (
	game: string | undefined,
	adapter: IframeType,
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig
) => {
	const [initialized, setInitialized] = useState(false);
	const [socialSettings, setSocialSettings] = useState<IFxIdSdkAdapterSocialSettings>();
	const [currentAccountId, setCurrentAccountId] = useState(-1);
	const supportedPlatform = IframeTypeToSupportedPlatformMap[adapter];
	const queryClient = useQueryClient();

	const onAccountChange = (e: IFxIdSdkOnAccountChangedEvent) => {
		console.log("useFxIdSdk onAccountChange");
		console.log("Clearing query caches");
		queryClient.clear();

		console.log("Settings new account id");
		setCurrentAccountId(e.newAccountId);
	};

	useAsyncEffect(async () => {
		if (!game) return;

		if (window.FxIdSdk !== undefined) {
			// eslint-disable-next-line no-console
			console.log("%c🛑🛑🛑 Повторная инициализация FxId SDK! 🛑🛑🛑", "font-weight: bold; font-size: 20px;");
			// eslint-disable-next-line no-console
			console.error(
				"SDK может быть инициализирован только один раз",
				"\nПроверьте дерево компонентов на дубликаты useFxIdSdk"
			);
			return;
		}

		// eslint-disable-next-line no-console
		console.log("Initializing FxIdSdk");
		window.FxIdSdk = new FxIdSdk(game, supportedPlatform, portalConfig);
		await window.FxIdSdk.InitializeSocialAdapter();
		await window.FxIdSdk.LoginToFxId();

		const socialSettings = await window.FxIdSdk.SocialSettings();
		setSocialSettings(socialSettings);

		setInitialized(true);
	}, []);

	useEffect(() => {
		if (window.FxIdSdk == null) return;
		window.FxIdSdk.OnChangeAccount.subscribe(onAccountChange);

		return () => {
			if (window.FxIdSdk == null) return;
			window.FxIdSdk.OnChangeAccount.unsubscribe(onAccountChange);
		};
	});

	/** useAsyncEffect некорректно ведет себя с функцией очистки. Для нормальной работы нужен обычный эффект */
	useEffect(() => () => (window.FxIdSdk = undefined), []);

	return { initialized, socialSettings, currentAccountId };
};
