/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdWebFeaturesPlayOtaGameVersion {
    Unspecified = 'Unspecified',
    Development = 'Development',
    Release = 'Release',
    UnknownDefaultOpenApi = '11184809'
}


export function FxIdWebFeaturesPlayOtaGameVersionFromJSON(json: any): FxIdWebFeaturesPlayOtaGameVersion {
    return FxIdWebFeaturesPlayOtaGameVersionFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayOtaGameVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayOtaGameVersion {
    return json as FxIdWebFeaturesPlayOtaGameVersion;
}

export function FxIdWebFeaturesPlayOtaGameVersionToJSON(value?: FxIdWebFeaturesPlayOtaGameVersion | null): any {
    return value as any;
}

