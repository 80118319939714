/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData
 */
export interface FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData
     */
    PaymentIntentClientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData
     */
    PublishableKey: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData
     */
    SessionUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PaymentIntentClientSecret" in value;
    isInstance = isInstance && "PublishableKey" in value;
    isInstance = isInstance && "SessionUrl" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData {
    return FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PaymentIntentClientSecret': json['PaymentIntentClientSecret'],
        'PublishableKey': json['PublishableKey'],
        'SessionUrl': json['SessionUrl'],
    };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaymentIntentClientSecret': value.PaymentIntentClientSecret,
        'PublishableKey': value.PublishableKey,
        'SessionUrl': value.SessionUrl,
    };
}

