import React from "react";
import { ContextModalProps, openContextModal } from "@mantine/modals";
import { Box, useMantineTheme } from "@mantine/core";
import { useFullscreen, useViewportSize } from "@mantine/hooks";

type ModalInnerProps = {
	url: string;
	fullScreen?: boolean;
};

/**
 * https://stackoverflow.com/a/58362609
 */
const ExitFullscreen = async () => {
	try {
		// As correctly mentioned in the accepted answer, exitFullscreen only works on document
		const cancellFullScreen =
			document.exitFullscreen ||
			(document as any).mozCancelFullScreen ||
			(document as any).webkitExitFullscreen ||
			(document as any).msExitFullscreen;
		console.log("Exiting fullscreen");
		await cancellFullScreen.call(document);
		console.log("Exited fullscreen");
	} catch (err) {
		// Тут будет ошибка дескать "мы не вышли из фулскрина потому-что мы не там - нам не важно
		// ignore
	}
};

export const IframeModal = (props: ContextModalProps<ModalInnerProps>) => {
	const { url, fullScreen } = props.innerProps;
	const { height } = useViewportSize();
	const theme = useMantineTheme();

	void ExitFullscreen();

	return (
		<Box
			sx={{
				minHeight: fullScreen ? `calc(${height}px - (${theme.spacing.lg * 2}px + 28px))` : "50vh",
				display: "flex"
			}}
		>
			<iframe style={{ minHeight: "100%", width: "100%", border: "none" }} src={url}></iframe>
		</Box>
	);
};

export const openIframeModal = (params: ModalInnerProps & { onClose?: () => void }) => {
	const { onClose, ...innerProps } = params;

	void ExitFullscreen();

	openContextModal({
		modal: "iframe",
		innerProps,
		fullScreen: innerProps.fullScreen,
		onClose,
		styles: (theme) => ({
			header: {
				marginBottom: innerProps.fullScreen ? theme.spacing.xs : undefined,
				marginTop: innerProps.fullScreen ? theme.spacing.lg * -1 + theme.spacing.xs : undefined
			}
		})
	});
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.test1 = () => openIframeModal({ url: "http://mozilla.org", fullScreen: true });
