import React, { useRef, useState } from "react";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import {
	Center,
	Space,
	Text,
	MantineProvider,
	TextInput,
	Button,
	Divider,
	Overlay,
	Group,
	LoadingOverlay,
	Box,
	Stack,
	Alert,
	Anchor,
	Loader,
	Image
} from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { ActionCodeSettings, sendSignInLinkToEmail } from "firebase/auth";
import { authStore, useAuthOptions, useEmailLinkAuthProvider, useFirebaseAuth } from "../../Stores";
import { z } from "zod";
import { Controller, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useHref, useNavigate } from "react-router-dom";
import { SOCIAL_LOGIN_BUTTONS, SocialLoginButtonProps } from "../SocialLoginButton";
import { getOS } from "../../Utils/Functions";
import * as Analytics from "../../Utils/Analytics";
import OpenApiClient from "../../Api/OpenApiClient";
import useScript from "../../Utils/Hooks/useScript";
import { setAuthToken } from "../../Api/Auth";
import { useProfileDataQuery } from "../../Api/Queries";
import { getValueFromConfigWithOverrides, TURNSTILE_SITE_KEYS } from "../../Config";
import { IframeType } from "../../Screens/WebPlayer/Hooks";
import {
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "../../Api/gen";

export type LoginWithFirebaseModalInnerProps = {
	authProviders: {
		allowed: string[];
		preferred?: string[];
	};
	onAuthSuccessful?: () => void | Promise<void>;
	fullScreen?: boolean;
	defaultEmail?: string;
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
	adapter: IframeType;
	gameConfig?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
};

interface ModalParams extends LoginWithFirebaseModalInnerProps {
	target?: string;
	withCloseButton?: boolean;
	analyticsData?: Parameters<typeof Analytics["site"]["openLoginModal"]>[number];
}

const TURNSTILE_SITE_KEY =
	process.env.NODE_ENV === "development"
		? "3x00000000000000000000FF"
		: TURNSTILE_SITE_KEYS[process.env.REACT_APP_TENANT_OVERRIDE];
const LOGIN_EMAIL_KEY = "loginEmail";

const SOCIAL_BUTTONS_SHOWN_BY_DEFAULT = 2;

const schema = z.object({
	email: z.string().email()
});

type FormData = z.infer<typeof schema>;

export const useSavedLoginEmail = () => {
	try {
		return useLocalStorage<string | undefined>({ key: LOGIN_EMAIL_KEY });
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error("[LOCAL STORAGE] Error accessing local storage", error);
		return [undefined, () => undefined] as const;
	}
};

const Header = (props: {
	fullScreen?: boolean;
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
	gameConfig?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
}) => {
	const { t } = useTranslation();
	const { fullScreen } = props;
	const largeScreen = useMediaQuery("(min-width: 576px)");
	const { portalConfig, gameConfig } = props;
	let {
		Logos: { BrandLogo }
	} = portalConfig.PortalPublicWebConfig.CompanyBranding;

	if (gameConfig?.PublicWebClientConfig.BrandLogoUrl != null) {
		BrandLogo = gameConfig?.PublicWebClientConfig.BrandLogoUrl;
	}

	const isCompact = !largeScreen || fullScreen;
	return (
		<Center
			mx={-20}
			mt={-20}
			mb={20}
			py={isCompact ? "xs" : "md"}
			sx={{
				background: "radial-gradient(68.49% 8804.92% at 54.48% 114.96%, #445161 0%, #04121b 100%);",
				color: "white"
			}}
		>
			<Text span size={isCompact ? 25 : 38}>
				{t("sign_in_modal.title")}
			</Text>
			<Space w="sm" />
			<Image
				src={BrandLogo}
				height={isCompact ? 50 : 79}
				width={isCompact ? 145 : 196}
				imageProps={{
					style: { objectFit: "contain", height: isCompact ? 50 : 79, width: isCompact ? 145 : 196 }
				}}
			/>
		</Center>
	);
};

export const LoginWithFirebaseModal = (props: ContextModalProps<LoginWithFirebaseModalInnerProps>) => {
	console.log("loginWithFirebase: %o", props);

	const { t } = useTranslation();
	const { fullScreen } = props.innerProps;
	const [showRestrictedForm, setShowRestrictedForm] = useState(
		(props.innerProps.authProviders.preferred?.length ?? 0) > 0
	);

	//TODO если новый tos/pp работает, то удалить эти строчки
	// const tosPath = useHref("/Docs/terms-of-service");
	// const ppPath = useHref("/Docs/privacy-policy");

	// выясняем, есть ли PP и TOS в publicConfig и branding
	const { portalConfig } = props.innerProps;
	const currentTosPath =
		getValueFromConfigWithOverrides<string>(
			portalConfig,
			"ExternalTosUrl",
			"ExternalTosUrl",
			props.innerProps.adapter,
			props.innerProps.gameConfig
		) ?? props.innerProps.gameConfig?.TosUrl;

	const currentPpPath =
		getValueFromConfigWithOverrides<string>(
			portalConfig,
			"ExternalPpUrl",
			"ExternalPpUrl",
			props.innerProps.adapter,
			props.innerProps.gameConfig
		) ?? props.innerProps.gameConfig?.PpUrl;

	return (
		<MantineProvider>
			<Header fullScreen={fullScreen} portalConfig={portalConfig} gameConfig={props.innerProps.gameConfig} />
			<Box
				sx={(theme) => ({
					position: "relative",
					margin: theme.spacing.lg * -1,
					paddingBlock: fullScreen ? theme.spacing.md : theme.spacing.lg,
					paddingInline: fullScreen
						? `max(${theme.spacing.md}px, env(safe-area-inset-left, 20px))`
						: theme.spacing.lg,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					flexGrow: 1
				})}
			>
				{showRestrictedForm ? (
					<RestrictedForm
						{...props.innerProps}
						modalId={props.id}
						onShowMoreOptionsClick={() => setShowRestrictedForm(false)}
					/>
				) : (
					<RegularForm {...props.innerProps} modalId={props.id} />
				)}
				<Text mt="md" size="xs" color="dimmed" align="center">
					<Trans t={t} i18nKey="sign_in_modal.disclaimer">
						<Anchor
							href={currentTosPath ? String(currentTosPath) : "/Docs/terms-of-service"}
							target="_blank"
							rel="noreferrer"
						/>
						<Anchor
							href={currentPpPath ? String(currentPpPath) : "/Docs/privacy-policy"}
							target="_blank"
							rel="noreferrer"
						/>
					</Trans>
				</Text>
			</Box>
		</MantineProvider>
	);
};

const RegularForm = (props: LoginWithFirebaseModalInnerProps & { modalId: string }) => {
	console.log("RegularForm: %o", props);

	const { t } = useTranslation();
	const { onAuthSuccessful, modalId, defaultEmail } = props;

	const authOptions = useAuthOptions();

	const [savedLoginEmail] = useSavedLoginEmail();

	const [authInProgress, setAuthInProgress] = useState(false);
	const [socialButtonsDisplayLimit, setSocialButtonsDisplayLimit] = useState<number | undefined>(
		SOCIAL_BUTTONS_SHOWN_BY_DEFAULT
	);

	const form = useForm<FormData>({
		defaultValues: { email: defaultEmail ?? "" },
		resolver: zodResolver(schema)
	});
	const {
		formState: { isSubmitSuccessful }
	} = form;

	const authProviders = props.authProviders?.preferred ?? props.authProviders.allowed;

	return (
		<>
			{isSubmitSuccessful && (
				<Overlay zIndex={201} opacity={1} p="lg" component={Stack} justify="space-between">
					<Alert title={t("sign_in_modal.success_alert.title")} color="green">
						<Trans t={t} i18nKey="sign_in_modal.success_alert.text" values={{ email: savedLoginEmail }} />
					</Alert>
					<Button sx={{ alignSelf: "center" }} onClick={() => closeModal(modalId)}>
						{t("button_close")}
					</Button>
				</Overlay>
			)}
			<LoadingOverlay visible={authInProgress} />
			<EmailLoginForm
				form={form}
				modalId={modalId}
				authInProgress={authInProgress}
				onAuthStarted={() => setAuthInProgress(true)}
				onAuthSettled={() => setAuthInProgress(false)}
				gameConfig={props.gameConfig}
			/>
			<Divider label={t("sign_in_modal.separator_text")} labelPosition="center" mt="md" />
			<Group spacing="md" position="center" mt="xs">
				{authProviders
					.reduce<Array<(props: SocialLoginButtonProps) => JSX.Element | null>>((acc, cur) => {
						const SocialButton = SOCIAL_LOGIN_BUTTONS.get(cur);
						if (SocialButton && acc.length < (socialButtonsDisplayLimit ?? Number.POSITIVE_INFINITY))
							acc.push(SocialButton);
						return acc;
					}, [])
					.map((SocialButton, index) => (
						<SocialButton
							key={index}
							onAuthStarted={() => setAuthInProgress(true)}
							onAuthSettled={() => setAuthInProgress(false)}
							gameConfig={props.gameConfig}
							onAuthSuccessful={() => {
								void onAuthSuccessful?.();
								closeModal(modalId);
							}}
							disabled={authInProgress}
						/>
					))}
			</Group>
			{socialButtonsDisplayLimit && (
				<Center mt="xs">
					<Text
						c="blue"
						sx={(theme) => ({
							":active": theme.activeStyles,
							":hover": { textDecoration: "underline" },
							cursor: "pointer"
						})}
						onClick={() => setSocialButtonsDisplayLimit(undefined)}
					>
						{t("sign_in_modal.more_options")}
					</Text>
				</Center>
			)}
		</>
	);
};

const EmailLoginForm = (props: {
	modalId: string;
	authInProgress: boolean;
	onAuthStarted: () => void;
	onAuthSettled: () => void;
	form: UseFormReturn<FormData>;
	gameConfig?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
}) => {
	const { t } = useTranslation();
	const { modalId, authInProgress, form, onAuthStarted, onAuthSettled } = props;
	const { gameConfig } = props;

	const currentGame = gameConfig?.GameSystemName;

	const widgetContainerRef = useRef<HTMLDivElement>(null);
	const [showCaptchaOverlay, setShowCaptchaOverlay] = useState(false);

	const { refetch: refetchProfileData } = useProfileDataQuery();

	const {
		control,
		handleSubmit,
		formState: { errors }
	} = form;

	const redirectPath = useHref("/LoginFromEmail");
	const auth = useFirebaseAuth();
	const navigate = useNavigate();
	const [, updateSavedLoginEmail] = useSavedLoginEmail();

	const emailLinkAuthProvider = useEmailLinkAuthProvider();

	const status = useScript("https://challenges.cloudflare.com/turnstile/v0/api.js");

	const handleEmailSubmit: SubmitHandler<FormData> = async (data) => {
		if (emailLinkAuthProvider === "firebase") {
			onAuthStarted();
			const url = new URL(redirectPath, window.location.origin);
			const actionCodeSettings: ActionCodeSettings = {
				url: url.toString(),
				handleCodeInApp: true
			};

			try {
				if (!auth) throw new Error("Firebase app is not initialized! Call firebaseInit() first");
				await sendSignInLinkToEmail(auth, data.email, actionCodeSettings);
				updateSavedLoginEmail(data.email);
				authStore.setState((state) => {
					state.isEmailAuthSuccessful = true;
				});
			} catch (error) {
				closeModal(modalId);
				if (error instanceof Error)
					navigate("/error", { state: { status: 0, body: JSON.stringify({ ...error }) } });
			}
			onAuthSettled();
			return;
		}

		const CaptchaResult = await new Promise<string | null>((resolve) => {
			if (widgetContainerRef.current === null) return resolve(null);
			setShowCaptchaOverlay(true);
			window.turnstile?.render(widgetContainerRef.current, {
				sitekey: TURNSTILE_SITE_KEY,
				theme: "light",
				callback: (token) => resolve(token)
			});
		});
		if (!CaptchaResult) {
			// eslint-disable-next-line no-console
			console.error("[AUTH] No captcha result");
			closeModal(modalId);
			navigate("/error", {
				state: { status: 0, body: JSON.stringify({ error: "No captcha result in authorization" }) }
			});
			return;
		}
		onAuthStarted();
		try {
			const response = await OpenApiClient.Invite.fxIdWebFeaturesInviteEmailInviteEndpoint({
				CaptchaResult,
				Email: data.email,
				GameSystemName: currentGame
			});
			if (!response.Success) {
				closeModal(modalId);
				navigate("/error", { state: { status: 0, body: response.Error } });
				return;
			}
			if (response.Token) {
				setAuthToken(response.Token);
				await refetchProfileData();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error("[AUTH] Error while generating email invite", error);
			closeModal(modalId);
			if (error instanceof Error)
				navigate("/error", { state: { status: 0, body: JSON.stringify({ ...error }) } });
			return;
		}
		authStore.setState((state) => {
			state.isEmailAuthSuccessful = true;
		});
		onAuthSettled();
		updateSavedLoginEmail(data.email);
	};
	const sendEmailAvailable = errors.email === undefined && !authInProgress && status === "ready";
	return (
		<form onSubmit={handleSubmit(handleEmailSubmit)}>
			<Stack spacing={"md"} sx={{ position: "relative" }}>
				<Controller
					control={control}
					name="email"
					render={({ field, fieldState }) => (
						<TextInput
							{...field}
							label={t("sign_in_modal.email.label")}
							description={t("sign_in_modal.email.description")}
							type="email"
							id="email"
							error={fieldState.error?.message}
						/>
					)}
				/>
				<Button type="submit" disabled={!sendEmailAvailable}>
					{t("sign_in_modal.send_link")}
				</Button>
				<Overlay
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						visibility: !showCaptchaOverlay ? "hidden" : undefined
					}}
					opacity={1}
				>
					<Loader />
					<Box
						ref={widgetContainerRef}
						sx={(theme) => ({
							...theme.fn.cover(),
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)"
						})}
					/>
				</Overlay>
			</Stack>
		</form>
	);
};

const RestrictedForm = (
	props: LoginWithFirebaseModalInnerProps & { modalId: string; onShowMoreOptionsClick: () => void }
) => {
	const { t } = useTranslation();
	const { onAuthSuccessful, authProviders, modalId, onShowMoreOptionsClick } = props;
	const authOptions = useAuthOptions();
	const [authInProgress, setAuthInProgress] = useState(false);
	const authShown = authProviders.preferred ?? authProviders.allowed;
	return (
		<>
			<LoadingOverlay visible={authInProgress} />
			<Group spacing="md" position="center" py="lg">
				{authShown.map((option, index) => {
					const SocialButton = SOCIAL_LOGIN_BUTTONS.get(option);
					if (!SocialButton) {
						// eslint-disable-next-line no-console
						console.warn("No login button for option:", option);
						return null;
					}
					return (
						<SocialButton
							key={index}
							large
							onAuthStarted={() => setAuthInProgress(true)}
							onAuthSettled={() => setAuthInProgress(false)}
							onAuthSuccessful={() => {
								void onAuthSuccessful?.();
								closeModal(modalId);
							}}
							disabled={authInProgress}
						/>
					);
				})}
			</Group>
			<Divider label={t("sign_in_modal.separator_text")} labelPosition="center" mt="md" />
			<Button variant="subtle" sx={{ alignSelf: "center" }} onClick={onShowMoreOptionsClick}>
				{t("sign_in_modal.more_options")}
			</Button>
		</>
	);
};

export const openLoginWithFirebaseModal = (props: ModalParams) => {
	authStore.setState((state) => {
		state.isEmailAuthSuccessful = false;
	});

	console.log("openLoginWithFirebaseModal props: %o", props);

	const os = getOS();
	const isMobile = os === "android" || os === "ios";

	const {
		target,
		fullScreen = !window.matchMedia("(min-width: 576px)").matches || isMobile,
		withCloseButton,
		analyticsData,
		...innerProps
	} = props;

	void Analytics.site.openLoginModal({ ...analyticsData });
	window.FxIdSdk?.DispatchDisableKeyboardInputCapture();
	return openContextModal({
		modal: "loginWithFirebase",
		styles: (theme) => ({
			header: { position: "absolute", right: 20, top: 10 },
			modal: { backgroundColor: theme.white, color: theme.black },
			body: { height: "100%", display: "flex", flexDirection: "column" }
		}),
		size: "lg",
		target,
		withCloseButton: withCloseButton ?? true,
		fullScreen,
		innerProps: { ...innerProps, fullScreen },
		onClose: () => {
			window.FxIdSdk?.DispatchEnableKeyboardInputCapture();
			// TODO: Переключить на настройку из publicConfig, когда она появится
			// const shouldRefresh =
			// 	authStore.getState().isEmailAuthSuccessful && innerProps.publicConfig.Auth.ShouldRefreshAfterEmailSent;
			const shouldRefresh = authStore.getState().isEmailAuthSuccessful;
			if (shouldRefresh) window.location.reload();
		}
	});
};
