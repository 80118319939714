import "./index.css";
import "./index.scss";

import React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./dayjs";
import * as serviceWorker from "./serviceWorker";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { getGoogleTagManagerTagData } from "./Utils/Analytics";
import * as Sentry from "@sentry/react";
import { BrowserOptions } from "@sentry/browser/types-ts3.8/client";

const googleTagManagerId = getGoogleTagManagerTagData();

if (googleTagManagerId != null) {
	console.log("Initializing google tag with id: %o", googleTagManagerId);

	const tagManagerArgs: TagManagerArgs = {
		gtmId: googleTagManagerId.tag
	};

	if (googleTagManagerId.env != null) {
		tagManagerArgs.auth = googleTagManagerId.env.auth;
		tagManagerArgs.preview = googleTagManagerId.env.preview;
	}

	TagManager.initialize(tagManagerArgs);

	window.dataLayer?.push("js", new Date());
}

const integrations: Sentry.BrowserOptions["integrations"] = [
	Sentry.browserTracingIntegration(),
	Sentry.replayIntegration({
		maskAllText: false,
		blockAllMedia: false
	})
];

// if (process.env.REACT_APP_ENV_OVERRIDE_NAME !== "prod") {
// 	integrations.push(
// 		Sentry.feedbackIntegration({
// 			colorScheme: "dark",
// 			showName: false
// 		})
// 	);
// }

Sentry.init({
	dsn: "https://7cb05911025b9bc5fc5c5182ca0542d4@sentry.fxgam.es/4",
	tunnel: process.env.PUBLIC_URL == null ? "/api/s_tunnel" : process.env.PUBLIC_URL + "/api/s_tunnel",
	integrations: integrations,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: process.env.REACT_APP_ENV_OVERRIDE_NAME === "prod" ? 0.1 : 100, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Функция, которая предотвращает вызов контекстного меню
function disableContextMenu(event: MouseEvent) {
	event.preventDefault();
}

// Назначение обработчика события на весь документ и окно
document.addEventListener("contextmenu", disableContextMenu);
window.addEventListener("contextmenu", disableContextMenu);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
const basename =
	process.env.REACT_APP_BUILD_MODE !== "standalone"
		? process.env.REACT_APP_ROUTER_BASEPATH ?? process.env.PUBLIC_URL
		: undefined;

console.log(`Environment: ${process.env.REACT_APP_ENV_OVERRIDE_NAME}`);
console.log(`PublicUrl: ${process.env.PUBLIC_URL}`);
console.log(`Standalone: ${process.env.REACT_APP_BUILD_MODE}`);
console.log(`REACT_APP_ROUTER_BASEPATH: ${process.env.REACT_APP_ROUTER_BASEPATH}`);
console.log(`basename: ${basename}`);
console.log(`BuildId: ${process.env.REACT_APP_BUILD_ID}`);

const Router = process.env.REACT_APP_BUILD_MODE === "standalone" ? HashRouter : BrowserRouter;

root.render(
	<Router basename={basename}>
		<App />
	</Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
