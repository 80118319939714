import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesStoreCreatePaymentEmbeddingType,
	FxIdWebFeaturesStoreCreatePaymentResponse
} from "../gen";
import { createPayment } from "../Store";

type TVariables = {
	game: string;
	sku: string;
	currency: string;
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
	embedType: FxIdWebFeaturesStoreCreatePaymentEmbeddingType;
};

export const useCreatePaymentMutation = (
	options?: Omit<UseMutationOptions<FxIdWebFeaturesStoreCreatePaymentResponse, unknown, TVariables>, "mutationFn">
) => {
	return useMutation(
		({ game, sku, currency, platform, embedType }: TVariables) =>
			createPayment(game, platform, sku, currency, embedType),
		options
	);
};
