/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainEntitiesNewsPriority } from './FxIdDomainEntitiesNewsPriority';
import {
    FxIdDomainEntitiesNewsPriorityFromJSON,
    FxIdDomainEntitiesNewsPriorityFromJSONTyped,
    FxIdDomainEntitiesNewsPriorityToJSON,
} from './FxIdDomainEntitiesNewsPriority';
import type { FxIdWebFeaturesInfoNewsNewsL10nMessage } from './FxIdWebFeaturesInfoNewsNewsL10nMessage';
import {
    FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSON,
    FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSONTyped,
    FxIdWebFeaturesInfoNewsNewsL10nMessageToJSON,
} from './FxIdWebFeaturesInfoNewsNewsL10nMessage';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesInfoNewsNews
 */
export interface FxIdWebFeaturesInfoNewsNews {
    /**
     * 
     * @type {Array<FxIdWebFeaturesInfoNewsNewsL10nMessage>}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Messages: Array<FxIdWebFeaturesInfoNewsNewsL10nMessage>;
    /**
     * 
     * @type {FxIdDomainEntitiesNewsPriority}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Priority: FxIdDomainEntitiesNewsPriority;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    IconUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    ImageUrl?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesInfoNewsNews
     */
    Tags?: Array<string> | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInfoNewsNews interface.
 */
export function instanceOfFxIdWebFeaturesInfoNewsNews(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Messages" in value;
    isInstance = isInstance && "Priority" in value;

    return isInstance;
}

export function FxIdWebFeaturesInfoNewsNewsFromJSON(json: any): FxIdWebFeaturesInfoNewsNews {
    return FxIdWebFeaturesInfoNewsNewsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInfoNewsNews {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Messages': ((json['Messages'] as Array<any>).map(FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSON)),
        'Priority': FxIdDomainEntitiesNewsPriorityFromJSON(json['Priority']),
        'Link': !exists(json, 'Link') ? undefined : json['Link'],
        'IconUrl': !exists(json, 'IconUrl') ? undefined : json['IconUrl'],
        'ImageUrl': !exists(json, 'ImageUrl') ? undefined : json['ImageUrl'],
        'Tags': !exists(json, 'Tags') ? undefined : json['Tags'],
    };
}

export function FxIdWebFeaturesInfoNewsNewsToJSON(value?: FxIdWebFeaturesInfoNewsNews | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Messages': ((value.Messages as Array<any>).map(FxIdWebFeaturesInfoNewsNewsL10nMessageToJSON)),
        'Priority': FxIdDomainEntitiesNewsPriorityToJSON(value.Priority),
        'Link': value.Link,
        'IconUrl': value.IconUrl,
        'ImageUrl': value.ImageUrl,
        'Tags': value.Tags,
    };
}

