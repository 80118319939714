/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames = FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames {
    return FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesMerchantData);
    }

    return {};
}

