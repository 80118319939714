/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseTelegram
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseTelegram = FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseTelegram {
    return FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseTelegram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseTelegramToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseTelegram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseTelegramMerchantData);
    }

    return {};
}

