import { Box, CopyButton, Stack, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { ContextModalProps, openContextModal } from "@mantine/modals";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { getSiteDeploymentStatus } from "../../Utils/Functions";
import { useParams } from "react-router-dom";
import { useElementSize } from "@mantine/hooks";
import { Copy } from "../../Icons";
import { MdCheck } from "react-icons/md";
import { getI18n } from "react-i18next";

type ModalInnerProps = {
	link: string;
};

export const LinkShareModal = (props: ContextModalProps<ModalInnerProps>) => {
	const {
		innerProps: { link }
	} = props;
	const params = useParams();
	const { isIframe } = getSiteDeploymentStatus(params);
	const { ref, height } = useElementSize();
	const isCopyAvailable = !isIframe;
	const theme = useMantineTheme();
	return (
		<Stack sx={{ height: "50vh" }} align="center">
			<Box ref={ref} sx={{ flexGrow: 1, width: "100%", minHeight: 0, display: "flex" }}>
				<QRCodeSVG
					style={{ flexBasis: "100%" }}
					height={undefined}
					width={undefined}
					value={link}
					fgColor={theme.colors.dark[0]}
					bgColor={theme.colors.dark[7]}
				/>
			</Box>
			<CopyButton value={link}>
				{({ copied, copy }) => (
					<UnstyledButton
						component={isCopyAvailable ? "button" : "div"}
						sx={(theme) => ({
							minWidth: height,
							padding: theme.spacing.sm,
							gap: theme.spacing.sm,
							border: `1px solid ${copied ? theme.colors.green[2] : theme.colors.dark[2]}`,
							borderRadius: theme.radius.md,
							display: "flex",
							alignItems: "center",
							transition: `${theme.transitionTimingFunction} 100ms background-color`,
							backgroundColor: copied ? theme.colors.green : undefined,
							cursor: !isCopyAvailable ? "auto" : undefined,
							":active": isCopyAvailable ? theme.activeStyles : undefined,
							":hover": isCopyAvailable
								? {
										backgroundColor: !copied ? theme.colors.dark[6] : undefined
								  }
								: undefined
						})}
						onClick={isCopyAvailable ? copy : undefined}
					>
						<Text align="center" sx={{ flexGrow: 1 }}>
							{link}
						</Text>
						{isCopyAvailable && <>{copied ? <MdCheck size={24} /> : <Copy width={24} height={24} />}</>}
					</UnstyledButton>
				)}
			</CopyButton>
		</Stack>
	);
};

export const openLinkShareModal = (params: ModalInnerProps) =>
	openContextModal({ modal: "linkShare", innerProps: params, title: String(getI18n().t("link_share_modal.title")) });
