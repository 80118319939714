import React, { useState } from "react";
import { Box, CloseButton } from "@mantine/core";
import { useGlobalMessagesQueue } from "../../Stores";
import { useGlobalMessagesContext } from "./GlobalMessagesProvider";

export const GlobalMessage = () => {
	const [queue, { dequeue }] = useGlobalMessagesQueue();
	const [closedMessages, setClosedMessages] = useState(new Set<string>());
	const { messages } = useGlobalMessagesContext();
	const message = queue.find((m) => !closedMessages.has(m.id));
	if (queue.length === 0 || message === undefined) return null;
	const MessageElement = messages[message.id] ?? (() => null);
	const textColor = message.color.includes("yellow") ? "black" : undefined;
	return (
		<Box
			sx={(theme) => ({
				backgroundColor: theme.fn.themeColor(message.color),
				padding: theme.spacing.xs,
				display: "flex",
				justifyContent: "space-between",
				gap: theme.spacing.xs
			})}
		>
			<Box>{<MessageElement {...message} />}</Box>
			{message.dismissable && (
				<CloseButton
					onClick={() => {
						dequeue(message.id);
						setClosedMessages((prev) => new Set(prev.add(message.id)));
					}}
					sx={{ color: textColor ?? "white" }}
					variant="transparent"
					size="sm"
				/>
			)}
		</Box>
	);
};
