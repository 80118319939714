import { useViewportSize } from "@mantine/hooks";
import { FxIdDomainSettingsEGameOrientation } from "../../../Api/gen";

/**
 * Определяет ориентацию экрана - вертикальная или горизонтальная
 */
export const useOrientation = () => {
	console.log("useOrientation...");

	//TODO удалить строку, если код будет работать
	// const { width, height } = useViewportSize();
	const width = window.innerWidth;
	const height = window.innerHeight;

	const orientation =
		width >= height ? FxIdDomainSettingsEGameOrientation.Horizontal : FxIdDomainSettingsEGameOrientation.Vertical;

	console.log("Ширина экрана: ", width);
	console.log("Высота экрана: ", height);
	console.log("Вычисленная ориентация: ", orientation);

	return orientation;
};
