import { useProfileDataQuery } from "../../../Api/Queries";
import useAsyncEffect from "use-async-effect";
import { useState } from "react";
import { getSiteDeploymentStatus } from "../../../Utils/Functions";

export enum IServiceWorkerInstallationState {
	/**
	 * В сервис воркере нет изменений
	 */
	None,
	/**
	 * Сервис воркер впервые установился
	 */
	Success,
	/**
	 * Сервис воркер обновился, возможно, требуется перезагрузить страницу
	 */
	Update,
	/**
	 * Ошибка при установке сервис воркера
	 */
	Error
}

/**
 * Название файла сервис воркера
 * Он один для всех игр, но регистрируется с разной областью видимости
 */
const swFileName = "game-service-worker.js";

interface IUseServiceWorkerOptions {
	game?: string;
	onSuccess?: (registration: ServiceWorkerRegistration) => void;
	onUpdate?: (registration: ServiceWorkerRegistration) => void;
	unRegister?: boolean;
	singleGameSite: boolean;
}

function onStateChange(
	this: ServiceWorker,
	registration: ServiceWorkerRegistration,
	options: IUseServiceWorkerOptions,
	setInstallationState: (state: IServiceWorkerInstallationState) => void
) {
	if (this.state !== "installed") return;

	if (navigator.serviceWorker.controller) {
		console.log(
			"SW: New content is available and will be used when all tabs for this page are closed. See http://bit.ly/CRA-PWA."
		);
		setInstallationState(IServiceWorkerInstallationState.Update);
		if (options.onUpdate) {
			options.onUpdate(registration);
		}
	} else {
		console.log("SW: Content is cached for offline use.");
		setInstallationState(IServiceWorkerInstallationState.Success);
		if (options.onSuccess) {
			options.onSuccess(registration);
		}
	}
}

export const useServiceWorker = (options: IUseServiceWorkerOptions) => {
	const { game, unRegister } = options;
	const [serviceWorkerInstallationState, setServiceWorkerInstallationState] =
		useState<IServiceWorkerInstallationState>(IServiceWorkerInstallationState.None);
	const { data: profileData } = useProfileDataQuery();
	const isGameConnected = Boolean(profileData?.ConnectedGames.find((g) => g.Game === game));

	useAsyncEffect(async () => {
		if (!game || !isGameConnected || !("serviceWorker" in navigator)) return;

		if (unRegister) {
			try {
				const registration = await navigator.serviceWorker.ready;
				await registration.unregister();
			} catch (error) {
				console.log("SW: Error service worker unregister", error);
			}

			return;
		}

		// Сервис-воркер не будет работать, если PUBLIC_URL находится в другом источнике,
		// отличном от того, на котором обслуживается наша страница.
		// У ФБ был прецедент, поэтому оставил это условие
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) return;

		const { singleGameSite } = options;

		const scope = singleGameSite ? `${process.env.PUBLIC_URL ?? ""}/` : `./${game}/`;

		try {
			const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/${swFileName}`, {
				scope
			});

			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker == null) return;

				installingWorker.onstatechange = function () {
					onStateChange.call(this, registration, options, setServiceWorkerInstallationState);
				};
			};
		} catch (error) {
			setServiceWorkerInstallationState(IServiceWorkerInstallationState.Error);
			console.error("SW: Error during service worker registration:", error);
		}
	}, [game, isGameConnected, unRegister]);

	return {
		serviceWorkerInstallationState
	};
};
