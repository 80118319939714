/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki = FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki {
    return FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiMerchantData);
    }

    return {};
}

