/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseYookassa
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseYookassa = FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseYookassa {
    return FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseYookassa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseYookassaToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseYookassa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseYookassaMerchantData);
    }

    return {};
}

