import React from "react";

export const LogoTelegram = React.forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(function LogoTelegram(
	props,
	ref
) {
	return (
		<svg ref={ref} fill="#fff" {...props}>
			<use xlinkHref="#icon-telegram_logo"></use>
		</svg>
	);
});
