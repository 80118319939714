import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Group, Space, Stack, Text, TextInput } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { Auth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { loginWithFirebaseUser } from "../../Api/Firebase";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
import { useSavedLoginEmail } from "../../Components/Modals";
import { useFirebaseAuth, useHeaderLoginState } from "../../Stores";
import * as Analytics from "../../Utils/Analytics";
import OpenApiClient from "../../Api/OpenApiClient";
import { setAuthToken } from "../../Api/Auth";
import { useGamePublicConfigQuery } from "../../Api/Queries";
import { useGameContext } from "../WebPlayer/WebPlayerContext";
import { FxIdWebFeaturesGamesChooseGameResponsePublicGameData } from "../../Api/gen";

const schema = z.object({
	email: z.string().email()
});

const defaultValues: z.infer<typeof schema> = {
	email: ""
};

async function loginWithEmail(params: { auth: Auth; email: string; game: string; marketingEnabled?: boolean }) {
	if (!isSignInWithEmailLink(params.auth, window.location.href)) return;
	const { user } = await signInWithEmailLink(params.auth, params.email, window.location.href);
	return loginWithFirebaseUser(params.game, user, params.marketingEnabled);
}

export const LoginFromEmail = (props: {
	isMainSite: boolean;
	gameConfig?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
}) => {
	const { t } = useTranslation();
	const [, { disable: disableHeaderLogin, enable: enableHeaderLogin }] = useHeaderLoginState();
	const auth = useFirebaseAuth();
	const [savedLoginEmail, updateSavedLoginEmail] = useSavedLoginEmail();
	const [searchParams] = useSearchParams();
	const marketingEnabled = searchParams.get("marketingEnabled") === "true";
	const navigate = useNavigate();

	const { isMainSite, gameConfig } = props;
	const isSingleGame = !isMainSite && !!gameConfig;

	const { data } = useGamePublicConfigQuery({ enabled: isSingleGame, gameId: gameConfig?.GameSystemName ?? "" });

	const isLoginInitiated = useRef(false);

	const { mutate: login } = useMutation(loginWithEmail, {
		onMutate: () => {
			isLoginInitiated.current = true;
		},
		onSuccess: () => {
			Analytics.site
				.login({ method: "email" })
				.then(() => {
					navigate("/");
					updateSavedLoginEmail(undefined);
				})
				.catch((err) => {
					console.error(err);
					navigate("/");
					updateSavedLoginEmail(undefined);
				});
		},
		onError: (error) => navigate("/error", { state: { status: 0, body: JSON.stringify(error) } })
	});

	const form = useForm({ defaultValues, resolver: zodResolver(schema) });
	const {
		control,
		handleSubmit,
		formState: { isSubmitting }
	} = form;

	useEffect(() => {
		disableHeaderLogin();
		return () => enableHeaderLogin();
	}, [disableHeaderLogin, enableHeaderLogin]);

	useEffect(() => {
		if (!savedLoginEmail || isLoginInitiated.current || !auth || (isSingleGame && !data)) return;
		login({
			auth,
			email: savedLoginEmail,
			marketingEnabled,
			game: data?.GameSystemName ?? ""
		});
	}, [auth, data, isSingleGame, login, marketingEnabled, savedLoginEmail]);

	const showLoader = savedLoginEmail || isSubmitting;
	return (
		<Stack spacing="md" justify="flex-start" align="center">
			<Group position="center" sx={{ visibility: !showLoader ? "hidden" : undefined }}>
				<LoadingIndicator />
			</Group>
			{!savedLoginEmail && (
				<Stack align="center" spacing="md">
					<Box
						component="form"
						onSubmit={handleSubmit(
							(formData) =>
								auth &&
								login({
									auth,
									email: formData.email,
									marketingEnabled,
									game: data?.GameSystemName ?? ""
								})
						)}
						sx={(theme) => ({ [theme.fn.smallerThan("sm")]: { alignSelf: "stretch" } })}
					>
						<Text>{t("login_from_email.message")}</Text>
						<Space h="md" />
						<Controller
							control={control}
							name="email"
							render={({ field, fieldState, formState: { isSubmitting } }) => (
								<>
									<TextInput
										label={t("login_from_email.email.title")}
										id="email"
										{...field}
										error={fieldState.error?.message}
									/>
									<Space h="md" />
									<Button
										type="submit"
										fullWidth
										disabled={fieldState.error !== undefined || isSubmitting}
									>
										{t("button_sign_in")}
									</Button>
								</>
							)}
						/>
					</Box>
				</Stack>
			)}
		</Stack>
	);
};

export const LoginFromFxEmail = () => {
	const { t } = useTranslation();

	const [, { disable: disableHeaderLogin, enable: enableHeaderLogin }] = useHeaderLoginState();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const isLoginInitiated = useRef(false);
	const [, updateSavedLoginEmail] = useSavedLoginEmail();
	const [redirectingTo, setRedirectingTo] = useState<string | null>();
	const { mutate: login } = useMutation(
		async () => {
			if (isLoginInitiated.current) return;
			isLoginInitiated.current = true;
			const InviteToken = searchParams.get("inviteToken");
			if (!InviteToken) throw new Error("[AUTH] No token in querystring");
			const result = await OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromEmailInviteEndpoint({
				InviteToken
			});
			if (!result.Success || !result.Token) throw new Error(String(result.Error));
			return result;
		},
		{
			onError: (error) => navigate("/error", { state: { status: 0, body: JSON.stringify(error) } }),
			onSuccess: (result) => {
				if (result?.Token != null) setAuthToken(result.Token);
				updateSavedLoginEmail(undefined);

				if (result?.RedirectUri != null) {
					setRedirectingTo(result.RedirectUri);
					window.location.href = result.RedirectUri;
					// navigate(result.RedirectUri);
				} else {
					navigate("/");
				}
			}
		}
	);
	useEffect(() => {
		disableHeaderLogin();
		return () => enableHeaderLogin();
	}, [disableHeaderLogin, enableHeaderLogin]);

	useEffect(() => {
		if (isLoginInitiated.current) return;
		login();
	}, [login]);

	return (
		<Stack spacing="md" justify="flex-start" align="center">
			<Group position="center">
				{!redirectingTo && <Text>{t("loading")}</Text>}
				{redirectingTo && (
					<Text>
						{t("redirecting")}
						<br />
						<a href={redirectingTo}>Click here for manual redirect ...</a>
					</Text>
				)}
				<LoadingIndicator />
			</Group>
		</Stack>
	);
};
