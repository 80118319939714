/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames = FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames {
    return FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesMerchantData);
    }

    return {};
}

