import React from "react";
import { GlobalMessageProps } from "../../../Stores";
import { Text } from "@mantine/core";
import { useTranslation, Trans } from "react-i18next";
import { openLoginWithFirebaseModal } from "../../Modals";
import { getPublicConfigFromMeta } from "../utils";
import { useGameContext } from "../../../Screens/WebPlayer/WebPlayerContext";
import {
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "../../../Api/gen";
import { IframeType } from "../../../Screens/WebPlayer/Hooks";

export const ValidateEmail = (props: GlobalMessageProps) => {
	const { t } = useTranslation();
	const textColor = props.color.includes("yellow") ? "black" : undefined;
	const publicConfig = getPublicConfigFromMeta(props.meta);
	const email =
		typeof props.meta === "object" &&
		props.meta !== null &&
		"email" in props.meta &&
		typeof props.meta.email === "string"
			? props.meta.email
			: "";

	const portalConfig = props.meta!.portalConfig as FxIdWebFeaturesGamesChooseGamePortalConfig;
	const gameConfig =
		props.meta?.gameConfig != null
			? (props.meta?.gameConfig as FxIdWebFeaturesGamesChooseGameResponsePublicGameData)
			: undefined;
	const adapter = (props.meta?.adapter != null ? (props.meta.adapter as IframeType) : undefined) ?? "fxid";
	return (
		<Text c={textColor}>
			<Trans
				t={t}
				i18nKey="global_messages.validate_email"
				components={[
					<Text key="0" component="span" fw="bold" c="blue" />,
					<Text
						key="1"
						c={textColor}
						td="underline"
						sx={(theme) => ({
							display: "inline-block",
							cursor: "pointer",
							fontWeight: "bold",
							":active": theme.activeStyles
						})}
						onClick={() =>
							openLoginWithFirebaseModal({
								onAuthSuccessful: () => window.location.reload(),
								authProviders: {
									allowed:
										gameConfig?.PublicWebClientConfig.Auth?.AllowedAuthProviders ??
										portalConfig.PortalPublicWebConfig.CompanyBranding.Auth.AllowedAuthProviders
								},
								target: "#web-player",
								defaultEmail: email,
								portalConfig,
								gameConfig,
								adapter
							})
						}
					/>
				]}
				values={{ email }}
			/>
		</Text>
	);
};
