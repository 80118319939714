import React from "react";
import { Box, Button, Text } from "@mantine/core";
import { ContextModalProps, openContextModal } from "@mantine/modals";

export const InfoModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: string }>) => (
	<>
		<Text size="sm">{innerProps.modalBody}</Text>
		<Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
			Close
		</Button>
	</>
);

export const openInfoModal = (message: string) =>
	openContextModal({
		modal: "info",
		innerProps: {
			modalBody: message
		},
		withCloseButton: false
	});
