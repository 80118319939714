import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { FxIdWebFeaturesProfileUpdateMarketingEnabledRequest } from "../gen";
import OpenApiClient from "../OpenApiClient";

export const useUpdateUserMarketingMutation = (
	options?: Omit<
		UseMutationOptions<unknown, unknown, FxIdWebFeaturesProfileUpdateMarketingEnabledRequest>,
		"mutationFn"
	>
) => {
	return useMutation<unknown, unknown, FxIdWebFeaturesProfileUpdateMarketingEnabledRequest>({
		mutationFn: (vars) => OpenApiClient.Profile.fxIdWebFeaturesProfileUpdateMarketingEnabledEndpoint(vars),
		...options
	});
};
