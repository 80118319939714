import React from "react";

export const FullscreenOpen = React.forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(function FullscreenOpen(
	props,
	ref
) {
	return (
		<svg ref={ref} fill="currentColor" {...props}>
			<use xlinkHref="#icon-full_screen_open"></use>
		</svg>
	);
});
