/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStoreOrderProductData } from './FxIdApplicationStoreOrderProductData';
import {
    FxIdApplicationStoreOrderProductDataFromJSON,
    FxIdApplicationStoreOrderProductDataFromJSONTyped,
    FxIdApplicationStoreOrderProductDataToJSON,
} from './FxIdApplicationStoreOrderProductData';
import type { FxIdDomainStoreEnumsMerchant } from './FxIdDomainStoreEnumsMerchant';
import {
    FxIdDomainStoreEnumsMerchantFromJSON,
    FxIdDomainStoreEnumsMerchantFromJSONTyped,
    FxIdDomainStoreEnumsMerchantToJSON,
} from './FxIdDomainStoreEnumsMerchant';
import type { FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames } from './FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames';
import type { FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames } from './FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames';
import type { FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRu } from './FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRu';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRuFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRuFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRuToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRu';
import type { FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki } from './FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki';
import type { FxIdWebFeaturesStoreCreatePaymentResponseStripe } from './FxIdWebFeaturesStoreCreatePaymentResponseStripe';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseStripeToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseStripe';
import type { FxIdWebFeaturesStoreCreatePaymentResponseTelegram } from './FxIdWebFeaturesStoreCreatePaymentResponseTelegram';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseTelegramToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseTelegram';
import type { FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlay } from './FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlay';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlayFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlayToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlay';
import type { FxIdWebFeaturesStoreCreatePaymentResponseWizQ } from './FxIdWebFeaturesStoreCreatePaymentResponseWizQ';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseWizQFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseWizQFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseWizQToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseWizQ';
import type { FxIdWebFeaturesStoreCreatePaymentResponseXsolla } from './FxIdWebFeaturesStoreCreatePaymentResponseXsolla';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseXsollaToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseXsolla';
import type { FxIdWebFeaturesStoreCreatePaymentResponseYandexGames } from './FxIdWebFeaturesStoreCreatePaymentResponseYandexGames';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseYandexGames';
import type { FxIdWebFeaturesStoreCreatePaymentResponseYookassa } from './FxIdWebFeaturesStoreCreatePaymentResponseYookassa';
import {
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseYookassaToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseYookassa';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentResponse
 */
export interface FxIdWebFeaturesStoreCreatePaymentResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     * @deprecated
     */
    ConfirmationUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    TransactionId: string;
    /**
     * 
     * @type {FxIdDomainStoreEnumsMerchant}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Merchant: FxIdDomainStoreEnumsMerchant;
    /**
     * 
     * @type {FxIdApplicationStoreOrderProductData}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    OrderProduct: FxIdApplicationStoreOrderProductData;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    OrderPrice: number;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    FacebookGames?: FxIdWebFeaturesStoreCreatePaymentResponseFacebookGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRu}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    MoneyMailRu?: FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRu | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseTelegram}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Telegram?: FxIdWebFeaturesStoreCreatePaymentResponseTelegram | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseXsolla}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Xsolla?: FxIdWebFeaturesStoreCreatePaymentResponseXsolla | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseYookassa}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Yookassa?: FxIdWebFeaturesStoreCreatePaymentResponseYookassa | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Odnoklassniki?: FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassniki | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlay}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    VkontaktePlay?: FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlay | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseYandexGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    YandexGames?: FxIdWebFeaturesStoreCreatePaymentResponseYandexGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    AbsoluteGames?: FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseStripe}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    Stripe?: FxIdWebFeaturesStoreCreatePaymentResponseStripe | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentResponseWizQ}
     * @memberof FxIdWebFeaturesStoreCreatePaymentResponse
     */
    WizQ?: FxIdWebFeaturesStoreCreatePaymentResponseWizQ | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "TransactionId" in value;
    isInstance = isInstance && "Merchant" in value;
    isInstance = isInstance && "OrderProduct" in value;
    isInstance = isInstance && "OrderPrice" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentResponseFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponse {
    return FxIdWebFeaturesStoreCreatePaymentResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ConfirmationUrl': !exists(json, 'ConfirmationUrl') ? undefined : json['ConfirmationUrl'],
        'TransactionId': json['TransactionId'],
        'Merchant': FxIdDomainStoreEnumsMerchantFromJSON(json['Merchant']),
        'OrderProduct': FxIdApplicationStoreOrderProductDataFromJSON(json['OrderProduct']),
        'OrderPrice': json['OrderPrice'],
        'FacebookGames': !exists(json, 'FacebookGames') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesFromJSON(json['FacebookGames']),
        'MoneyMailRu': !exists(json, 'MoneyMailRu') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRuFromJSON(json['MoneyMailRu']),
        'Telegram': !exists(json, 'Telegram') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseTelegramFromJSON(json['Telegram']),
        'Xsolla': !exists(json, 'Xsolla') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseXsollaFromJSON(json['Xsolla']),
        'Yookassa': !exists(json, 'Yookassa') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseYookassaFromJSON(json['Yookassa']),
        'Odnoklassniki': !exists(json, 'Odnoklassniki') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'VkontaktePlay': !exists(json, 'VkontaktePlay') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlayFromJSON(json['VkontaktePlay']),
        'YandexGames': !exists(json, 'YandexGames') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesFromJSON(json['YandexGames']),
        'AbsoluteGames': !exists(json, 'AbsoluteGames') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'Stripe': !exists(json, 'Stripe') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSON(json['Stripe']),
        'WizQ': !exists(json, 'WizQ') ? undefined : FxIdWebFeaturesStoreCreatePaymentResponseWizQFromJSON(json['WizQ']),
    };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ConfirmationUrl': value.ConfirmationUrl,
        'TransactionId': value.TransactionId,
        'Merchant': FxIdDomainStoreEnumsMerchantToJSON(value.Merchant),
        'OrderProduct': FxIdApplicationStoreOrderProductDataToJSON(value.OrderProduct),
        'OrderPrice': value.OrderPrice,
        'FacebookGames': FxIdWebFeaturesStoreCreatePaymentResponseFacebookGamesToJSON(value.FacebookGames),
        'MoneyMailRu': FxIdWebFeaturesStoreCreatePaymentResponseMoneyMailRuToJSON(value.MoneyMailRu),
        'Telegram': FxIdWebFeaturesStoreCreatePaymentResponseTelegramToJSON(value.Telegram),
        'Xsolla': FxIdWebFeaturesStoreCreatePaymentResponseXsollaToJSON(value.Xsolla),
        'Yookassa': FxIdWebFeaturesStoreCreatePaymentResponseYookassaToJSON(value.Yookassa),
        'Odnoklassniki': FxIdWebFeaturesStoreCreatePaymentResponseOdnoklassnikiToJSON(value.Odnoklassniki),
        'VkontaktePlay': FxIdWebFeaturesStoreCreatePaymentResponseVkontaktePlayToJSON(value.VkontaktePlay),
        'YandexGames': FxIdWebFeaturesStoreCreatePaymentResponseYandexGamesToJSON(value.YandexGames),
        'AbsoluteGames': FxIdWebFeaturesStoreCreatePaymentResponseAbsoluteGamesToJSON(value.AbsoluteGames),
        'Stripe': FxIdWebFeaturesStoreCreatePaymentResponseStripeToJSON(value.Stripe),
        'WizQ': FxIdWebFeaturesStoreCreatePaymentResponseWizQToJSON(value.WizQ),
    };
}

