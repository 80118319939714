/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions
 */
export interface FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions
     */
    Uid: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions
     */
    IpAddress: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions
     */
    Currency: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Uid" in value;
    isInstance = isInstance && "IpAddress" in value;
    isInstance = isInstance && "Currency" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions {
    return FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Uid': json['Uid'],
        'IpAddress': json['IpAddress'],
        'Currency': json['Currency'],
    };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptionsToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Uid': value.Uid,
        'IpAddress': value.IpAddress,
        'Currency': value.Currency,
    };
}

