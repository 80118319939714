/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainStoreEnumsSupportedWebPublishingPlatform } from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';
import {
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
} from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';
import type { FxIdWebFeaturesStoreCreatePaymentEmbeddingType } from './FxIdWebFeaturesStoreCreatePaymentEmbeddingType';
import {
    FxIdWebFeaturesStoreCreatePaymentEmbeddingTypeFromJSON,
    FxIdWebFeaturesStoreCreatePaymentEmbeddingTypeFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentEmbeddingTypeToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentEmbeddingType';
import type { FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames } from './FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames';
import type { FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay } from './FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay';
import {
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentRequest
 */
export interface FxIdWebFeaturesStoreCreatePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Country?: string | null;
    /**
     * 
     * @type {FxIdDomainStoreEnumsSupportedWebPublishingPlatform}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    VkontaktePlay?: FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlay | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    AbsoluteGames?: FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreCreatePaymentEmbeddingType}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    EmbeddingType: FxIdWebFeaturesStoreCreatePaymentEmbeddingType;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "Sku" in value;
    isInstance = isInstance && "WebPublishingPlatform" in value;
    isInstance = isInstance && "EmbeddingType" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequest {
    return FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'Sku': json['Sku'],
        'Currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'Country': !exists(json, 'Country') ? undefined : json['Country'],
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON(json['WebPublishingPlatform']),
        'VkontaktePlay': !exists(json, 'VkontaktePlay') ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayFromJSON(json['VkontaktePlay']),
        'AbsoluteGames': !exists(json, 'AbsoluteGames') ? undefined : FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'EmbeddingType': FxIdWebFeaturesStoreCreatePaymentEmbeddingTypeFromJSON(json['EmbeddingType']),
    };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'Sku': value.Sku,
        'Currency': value.Currency,
        'Country': value.Country,
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON(value.WebPublishingPlatform),
        'VkontaktePlay': FxIdWebFeaturesStoreCreatePaymentRequestVkontaktePlayToJSON(value.VkontaktePlay),
        'AbsoluteGames': FxIdWebFeaturesStoreCreatePaymentRequestAbsoluteGamesToJSON(value.AbsoluteGames),
        'EmbeddingType': FxIdWebFeaturesStoreCreatePaymentEmbeddingTypeToJSON(value.EmbeddingType),
    };
}

