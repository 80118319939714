import { BackgroundImage, Box, Container } from "@mantine/core";
import React from "react";
import { CDN_PREFIX } from "./Cdn";

import headerCharactersSrc1 from "../Images/companies/fxgames/header/header-characters-1.webp";
import headerCharactersSrc2 from "../Images/companies/fxgames/header/header-characters-2.webp";
import headerCharactersSrc3 from "../Images/companies/fxgames/header/header-characters-3.webp";
import headerCharactersSrc4 from "../Images/companies/fxgames/header/header-characters-4.webp";
import headerCharactersFallbackSrc from "../Images/companies/fxgames/header/header-characters-fallback.png";
import headerBeamsSrc from "../Images/companies/fxgames/header/header-beams.png";

const headerCharacters1 = `${CDN_PREFIX}${headerCharactersSrc1}`;
const headerCharacters2 = `${CDN_PREFIX}${headerCharactersSrc2}`;
const headerCharacters3 = `${CDN_PREFIX}${headerCharactersSrc3}`;
const headerCharacters4 = `${CDN_PREFIX}${headerCharactersSrc4}`;
const headerCharactersFallback = `${CDN_PREFIX}${headerCharactersFallbackSrc}`;
const headerBeams = `${CDN_PREFIX}${headerBeamsSrc}`;

export const HeaderElement = (type: string) => {
	if (type === "fx") {
		return FX_HEADER;
	}

	return FX_HEADER;
};

export const FX_HEADER = (
	<Box
		sx={{
			backgroundImage:
				"radial-gradient(94.18% 94.2% at 53.44% 100%, #fbd553 5.05%, #7e5858 42.69%, #3f2044 93.01%);",
			position: "absolute",
			inset: 0
		}}
	>
		<BackgroundImage src={headerBeams} sx={{ height: "100%" }}>
			<Container fluid sx={{ height: "100%", position: "relative", overflow: "hidden" }}>
				<Box
					component="img"
					srcSet={`${headerCharacters4} 500w, ${headerCharacters3} 1000w, ${headerCharacters2} 1500w,	${headerCharacters1} 2500w,`}
					sizes="100vw"
					src={headerCharactersFallback}
					sx={{
						position: "absolute",
						bottom: -1,
						left: 0,
						transformOrigin: "bottom center",
						scale: "1.2",
						"@media (min-width: 576px)": { scale: "1" }
					}}
				/>
			</Container>
		</BackgroundImage>
		<Box
			sx={{
				position: "absolute",
				inset: 0,
				"@media (min-width: 576px)": {
					backgroundImage:
						"linear-gradient(178.87deg, rgba(7, 21, 70, 0.58) 0.97%, rgba(5, 14, 48, 0) 22.74%, rgba(3, 10, 33, 0) 62.16%, #000000 106.76%)"
				}
			}}
		/>
	</Box>
);
