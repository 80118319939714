/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData,
    instanceOfFxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData,
    FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSON,
    FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSONTyped,
    FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataToJSON,
} from './FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData';

/**
 * @type FxIdWebFeaturesStoreCreatePaymentResponseStripe
 * 
 * @export
 */
export type FxIdWebFeaturesStoreCreatePaymentResponseStripe = FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData;

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentResponseStripe {
    return FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentResponseStripe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreCreatePaymentResponseStripeToJSON(value?: FxIdWebFeaturesStoreCreatePaymentResponseStripe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData(value)) {
        return FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantDataToJSON(value as FxIdWebFeaturesStoreCreatePaymentResponseStripeMerchantData);
    }

    return {};
}

